<template>
  <div>
    <AppDataTable
      sort-by="name"
      :items="summaryTodos"
    >
      <template v-slot:top>
        <MeetingDialogTodoForm
          v-model="showDialogTodoForm"
          :fields="todoFields"
          :tw-sync-options="twSyncOptions"
          :attendees="attendees"
          :tw-projects="twProjects"
          :tw-tasklists="twTasklists"
          @manage-attendees="$emit('manage-attendees')"
          @save="updateTodoFields($event, false)"
          @save-next="updateTodoFields($event, true)"
          @delete="openDialogTodoConfirmDelete"
          @set-message="$emit('set-message', $event)"
          @close="closeDialogTodoForm"
        />

        <AppDialogConfirmDelete
          v-if="showDialogTodoConfirmDelete"
          message="You want to delete this todo?"
          @confirm="deleteTodo"
          @cancel="closeDialogTodoConfirmDelete"
        />

        <v-toolbar flat dense>
          <v-toolbar-title>
            <small>Todos Summary</small>
          </v-toolbar-title>
          <v-spacer />
          <v-tooltip v-if="isEditable" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="addTodo">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Todo</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <template v-if="items.length === 0">
            <tr class="eos-meeting__todo-summary--empty-state">
              <td class="text-center">
                <v-btn small outlined class="d-flex mx-auto" @click="addTodo">Add to-do</v-btn>
              </td>
            </tr>
          </template>
          <tr v-for="(item, index) in items" :key="`eos-conclude-todos-${index}`">
            <td class="pr-0" style="width:20px;">
              <v-icon>mdi-checkbox-blank-outline</v-icon>
            </td>
            <td
              class="pl-2 eos-meeting__todo-summary__email"
              :class="{ 'cursor-pointer': isEditable }"
              @click="editTodo(item.todoObj)"
            >
              <span class="font-weight-bold">{{ item.todoObj.email | nameByEmail(attendees) }}</span>
              <MeetingListOtherAssignees
                v-if="item.todoObj.otherAssignees && item.todoObj.otherAssignees.length > 0"
                :unique-id="item.todoObj.id"
                :attendees="attendees"
                :other-assignees="item.todoObj.otherAssignees"
              />
            </td>
            <td class="eos-meeting__todo-summary__type" :class="{ 'cursor-pointer': isEditable }">
              <v-chip v-if="item.type" small color="#F4F4F4">{{ item.type }}</v-chip>
            </td>
            <td class="pl-2" :class="{ 'cursor-pointer': isEditable }" @click="editTodo(item.todoObj)">{{ item.todoObj.todo }}</td>
            <td v-if="isEditable" style="width: 50px;">
              <div class="d-flex align-center justify-end mr-1">
                <v-tooltip v-if="item.todoObj.taskId && twSyncOptions.domain" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" size="14px" class="mr-2 cursor-pointer" v-text="'$vuetify.icons.tw-teamwork'" @click="$emit('go-to-tw-task', item.todoObj.taskId)" />
                  </template>
                  <span>Open Teamwork Task</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small class="mr-2" @click="editTodo(item.todoObj)">mdi-pencil</v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small @click="confirmAndDeleteTodo(item.todoObj)">mdi-delete</v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </AppDataTable>

    <v-btn v-if="summaryTodos.length > 0" depressed small class="ma-3" @click="addTodo">
      <v-icon x-small class="pr-2">mdi-plus</v-icon>Add To-do
    </v-btn>

    <p
      v-if="!twSyncOptions.accessToken || !twSyncOptions.projectId || !twSyncOptions.tasklistId"
      class="text-center mt-3 eos-meeting__todo-summary__tw-branding"
    >
      You know you can sync your todos with the project management software <AppTeamworkBranding />?
      <a href="https://teamwork.grsm.io/instantagencytools" target="_blank" class="text-decoration-none">Learn more</a>
      or click here to
      <a href="javascript:;" class="text-decoration-none" @click="$emit('show-sync-options')">sync now</a>
    </p>
  </div>
</template>

<script>
import AppTeamworkBranding from '@/components/shared/misc/AppTeamworkBranding'
import MeetingDialogTodoForm from '@/components/meeting/dialogs/MeetingDialogTodoForm'
import MeetingListOtherAssignees from '@/components/meeting/MeetingListOtherAssignees'

const TODO_TYPE_CARRIED_OVER = 'Carried over'
const TODO_TYPE_IDS_ISSUE = 'New (Task)'
const TODO_TYPE_EXTRA = 'New'

export default {
  name: 'MeetingStepConcludeTodos',
  components: {
    AppTeamworkBranding,
    MeetingDialogTodoForm,
    MeetingListOtherAssignees
  },
  props: {
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    initialIdsIssues: {
      type: Array,
      default: () => ([])
    },
    carriedOverTodos: {
      type: Array,
      default: () => ([])
    },
    extraTodos: {
      type: Array,
      default: () => ([])
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    twProjects: {
      type: Array,
      default: () => ([])
    },
    twTasklists: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      IDSIssues: [],
      summaryTodos: [],
      showDialogTodoForm: false,
      showDialogTodoConfirmDelete: false,
      todoFields: {
        email: '',
        projectId: null,
        tasklistId: null,
        taskId: null,
        todo: '',
        startDate: '',
        endDate: ''
      },
      defaultTodo: {
        email: this.loggedInUser.email,
        projectId: null,
        tasklistId: null,
        taskId: null,
        todo: '',
        startDate: '',
        endDate: '',
        synced: false
      }
    }
  },
  watch: {
    initialIdsIssues: {
      immediate: true,
      handler (issues) {
        this.IDSIssues = issues.slice().map((issue, index) => ({
          ...issue,
          id: issue.id || this.$helpers.generateUniqueId(),
          todos: (issue.todos || []).map(todo => {
            todo.id = todo.id || this.$helpers.generateUniqueId()
            return { todoObj: todo, issueId: issue.id, type: TODO_TYPE_IDS_ISSUE }
          })
        }))
        this.updateSummaryTodos()
      }
    },
    carriedOverTodos: {
      immediate: true,
      handler: 'updateSummaryTodos'
    },
    extraTodos: {
      immediate: true,
      handler: 'updateSummaryTodos'
    },
    twSyncOptions: {
      immediate: true,
      handler (options) {
        Object.keys(this.defaultTodo).forEach(key => {
          if (options[key]) this.defaultTodo[key] = options[key]
        })
      }
    }
  },
  methods: {
    updateSummaryTodos () {
      const issueTodos = this.IDSIssues.reduce((todos, issue) => [...todos, ...issue.todos], [])
      const carriedOverTodos = this.carriedOverTodos.map(todo => {
        todo.id = todo.id || this.$helpers.generateUniqueId()
        return { todoObj: todo, type: todo.isNew ? TODO_TYPE_EXTRA : TODO_TYPE_CARRIED_OVER }
      })
      const extraTodos = this.extraTodos.map(todo => {
        todo.id = todo.id || this.$helpers.generateUniqueId()
        return { todoObj: todo, type: TODO_TYPE_EXTRA }
      })

      this.summaryTodos = [...carriedOverTodos, ...issueTodos, ...extraTodos]
        .sort((a, b) => {
          const nameA = this.$options.filters.nameByEmail(a.todoObj.email, this.attendees)
          const nameB = this.$options.filters.nameByEmail(b.todoObj.email, this.attendees)
          return ('' + nameA).localeCompare(nameB)
        })
    },

    addTodo () {
      this.todoFields = {}
      Object.keys(this.defaultTodo).forEach(key => {
        this.todoFields[key] = this.twSyncOptions[key] || this.defaultTodo[key]
      })
      this.showDialogTodoForm = true
    },

    editTodo (todo) {
      if (!this.isEditable) return
      this.todoFields = { ...this.defaultTodo, ...todo, synced: false }
      this.showDialogTodoForm = true
    },

    updateTodoFields (todoFields, keepForNext) {
      this.todoFields = Object.assign({}, todoFields)
      this.saveTodo(keepForNext)
    },

    saveTodo (keepForNext = false) {
      if (this.todoFields.id) {
        const index = this.summaryTodos.findIndex(todo => todo.todoObj.id === this.todoFields.id)
        if (index === -1) return this.$emit('set-message', { type: 'error', message: 'Todo is deleted' })

        const todo = this.summaryTodos[index]
        // if it's not an issue todo - just save it
        if (!todo.issueId) {
          this.$set(this.summaryTodos[index], 'todoObj', this.todoFields)
        } else { // check issue is not deleted
          const issueIndex = this.IDSIssues.findIndex(issue => issue.id === todo.issueId)
          if (issueIndex === -1) return this.$emit('set-message', { type: 'error', message: 'Issue is deleted' })
          const todoIndex = this.IDSIssues[issueIndex].todos.findIndex(todo => todo.todoObj.id === this.todoFields.id)
          this.$set(this.IDSIssues[issueIndex].todos[todoIndex], 'todoObj', this.todoFields)
        }
      } else {
        const newTodoItem = Object.assign({
          id: this.$helpers.generateUniqueId()
        }, this.todoFields)
        this.summaryTodos.push({ todoObj: newTodoItem, type: TODO_TYPE_EXTRA })
      }

      const msg = `Todo ${(this.todoFields.id ? 'updated' : 'added')}`
      keepForNext ? this.todoFields.todo = '' : this.closeDialogTodoForm()

      this.emitUpdate()
      this.$emit('set-message', { type: 'success', message: msg })
    },

    confirmAndDeleteTodo (todo) {
      this.todoFields = todo
      this.openDialogTodoConfirmDelete()
    },

    closeDialogTodoForm () {
      this.showDialogTodoForm = false
    },

    openDialogTodoConfirmDelete () {
      this.showDialogTodoConfirmDelete = true
    },

    deleteTodo () {
      const index = this.summaryTodos.findIndex(todo => todo.todoObj.id === this.todoFields.id)

      if (index > -1) {
        const todo = this.summaryTodos[index]
        if (!todo.issueId) {
          this.summaryTodos.splice(index, 1)
        } else { // check issue is not deleted
          const issueIndex = this.IDSIssues.findIndex(issue => issue.id === todo.issueId)
          if (issueIndex === -1) return this.$emit('set-message', { type: 'error', message: 'Issue is deleted' })
          const todoIndex = this.IDSIssues[issueIndex].todos.findIndex(todo => todo.todoObj.id === this.todoFields.id)
          this.IDSIssues[issueIndex].todos.splice(todoIndex, 1)
        }
        this.emitUpdate()
      }

      if (this.todoFields.taskId) {
        this.$emit('delete-todo', this.todoFields.taskId)
      }

      this.closeDialogTodoConfirmDelete()
      this.closeDialogTodoForm()

      this.$emit('set-message', { type: 'success', message: 'Todo deleted' })
    },

    closeDialogTodoConfirmDelete () {
      this.showDialogTodoConfirmDelete = false
    },

    emitUpdate () {
      this.$emit('data-updated', {
        issues: this.IDSIssues.map(issue => ({
          ...issue,
          todos: issue.todos.map(todo => todo.todoObj)
        })),
        todos: this.summaryTodos.filter(todo => todo.type === TODO_TYPE_CARRIED_OVER).map(todo => todo.todoObj),
        extraTodos: this.summaryTodos.filter(todo => todo.type === TODO_TYPE_EXTRA).map(todo => todo.todoObj)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
tr.eos-meeting__todo-summary--empty-state:hover td {
  background-color: #fff;
}

.eos-meeting__todo-summary__email {
  width: 190px;
  white-space: nowrap;
}

.eos-meeting__todo-summary__type {
  width: 100px;
}

.eos-meeting__todo-summary__tw-branding {
  font-size: 14px;
}
</style>
