<template>
  <div>
    <MeetingDialogTodoForm
      v-model="showDialogTodoForm"
      :fields="todoFields"
      :tw-sync-options="twSyncOptions"
      :attendees="attendees"
      :tw-projects="twProjects"
      :tw-tasklists="twTasklists"
      with-status-and-progress
      @manage-attendees="$emit('manage-attendees')"
      @save="updateTodoFields($event, false)"
      @save-next="updateTodoFields($event, true)"
      @delete="openDialogTodoConfirmDelete"
      @set-message="$emit('set-message', $event)"
      @close="closeDialogTodoForm"
      @refresh-tasklist="$emit('refresh-tasklist', $event)"
    />

    <AppDialogConfirmDelete
      v-if="showDialogTodoConfirmDelete"
      message="You want to delete this todo?"
      @confirm="deleteTodo"
      @cancel="closeDialogTodoConfirmDelete"
    />

    <v-toolbar flat dense>
      <v-toolbar-title>
        <small>To-dos</small>
      </v-toolbar-title>

      <v-spacer />

      <v-tooltip v-if="isEditable" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="addTodo">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Todo</span>
      </v-tooltip>
    </v-toolbar>

    <div v-if="hasTodos" class="v-data-table theme--light">
      <div class="v-data-table__wrapper">
        <draggable
          v-model="groupedTodos"
          v-bind="{
            ...dragOptions,
            disabled: disableDrag
          }"
          tag="table"
          @change="updateSortOrder"
        >
          <tbody v-for="(group, groupIndex) in groupedTodos" :key="groupIndex">
            <tr v-if="groupIndex > 0"><td colspan="5" style="height: 1px;"></td></tr>
            <tr
              v-for="(item, index) in group.items"
              :key="`${groupIndex}-${index}`"
              :class="{ 'draggable-list-item': !disableDrag }"
            >
              <td style="width: 200px;">
                <div class="text-no-wrap">
                  <strong v-if="index === 0 || item.otherAssignees && item.otherAssignees.length > 0">{{ item.email | nameByEmail(attendees) }}</strong>
                  <MeetingListOtherAssignees
                    v-if="item.otherAssignees && item.otherAssignees.length > 0"
                    :unique-id="item.id"
                    :attendees="attendees"
                    :other-assignees="item.otherAssignees"
                  />
                </div>
              </td>
              <td class="ma-0 px-0 text-end" style="width: 50px;">
                <v-chip v-if="item.isNew" small color="#F4F4F4">New</v-chip>
              </td>
              <td class="ma-0 px-0 text-end" style="width: 30px;">
                <v-icon
                  dark
                  color="#333"
                  @click="toggleDoneStatus(item)"
                >{{ `mdi-checkbox-${item.status === 'Done' ? 'marked' : 'blank-outline'}` }}</v-icon>
              </td>
              <td>
                <div class="py-1 eos-tbl__content-grow" :class="{ 'cursor-pointer': isEditable }" @click="editTodo(item, $event)">
                  <span v-html="$options.filters.nlToBr(item.todo)"></span>
                </div>
              </td>
              <td style="width: 150px;">
                <v-chip :color="item.status === 'Done' ? 'green' : '#333'" dark @click="editTodo(item)">
                  <v-avatar v-if="item.status === 'Done'" left>
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  {{ item.status }}
                  <template v-if="item.progress > 0 && item.status !== 'Done'">{{ item.progress }}%</template>
                </v-chip>
              </td>
              <td v-if="isEditable" style="width: 50px;">
                <div class="d-flex align-center justify-end mr-1">
                  <v-tooltip v-if="item.taskId && twSyncOptions.domain" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" size="14px" class="mr-2 cursor-pointer" v-text="'$vuetify.icons.tw-teamwork'" @click="$emit('go-to-tw-task', item.taskId)" />
                    </template>
                    <span>Open Teamwork Task</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small class="mr-2" @click="logIssue(item)">mdi-alert-octagon-outline</v-icon>
                    </template>
                    <span>Log an issue</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small class="mr-2" @click="editTodo(item)">mdi-pencil</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small @click="openDialogTodoConfirmDelete(item)">mdi-delete</v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </draggable>
      </div>
    </div>

    <template v-if="isEditable">
      <div v-if="hasTodos" class="pa-0">
        <v-btn depressed small class="ma-3" @click="addTodo">
          <v-icon x-small class="pr-2">mdi-plus</v-icon>Add To-do
        </v-btn>
      </div>
      <v-btn v-else small outlined class="d-flex mt-3 mx-auto" @click="addTodo">Add the first to-do</v-btn>
    </template>
    <div v-else-if="!hasTodos" class="text-center">
      <p>No todo found</p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import MeetingDialogTodoForm from '@/components/meeting/dialogs/MeetingDialogTodoForm'
import MeetingListOtherAssignees from '@/components/meeting/MeetingListOtherAssignees'

export default {
  name: 'MeetingStepTodos',
  components: {
    draggable,
    MeetingDialogTodoForm,
    MeetingListOtherAssignees
  },
  props: {
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    initialTodosData: {
      type: Object,
      default: () => ({})
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    dragOptions: {
      type: Object,
      default: () => ({})
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    meetingHasStarted: {
      type: Boolean,
      default: false
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    twProjects: {
      type: Array,
      default: () => ([])
    },
    twTasklists: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      todosData: {
        sortOrder: [],
        todos: []
      },
      showDialogTodoForm: false,
      showDialogTodoConfirmDelete: false,
      todoFields: {
        email: '',
        projectId: null,
        tasklistId: null,
        taskId: null,
        todo: '',
        status: 'Not done',
        progress: 0,
        startDate: '',
        endDate: ''
      },
      defaultTodoItem: {
        email: this.loggedInUser.email,
        projectId: null,
        tasklistId: null,
        taskId: null,
        todo: '',
        status: 'Not done',
        progress: 0,
        startDate: '',
        endDate: '',
        synced: false
      },
      todoToDelete: null,
      projects: [],
      tasklists: [],
      fetchingTasklists: false,
      groupedTodos: []
    }
  },
  computed: {
    todoFormTitle () {
      return `${(this.todoFields.id ? 'Edit' : 'New')} Todo`
    },
    disableDrag () {
      return this.todosData.sortOrder.length < 2 || !this.isEditable
    },
    hasTodos () {
      return this.todosData.todos.length > 0
    }
  },
  watch: {
    initialTodosData: {
      immediate: true,
      handler (data) {
        this.todosData = { ...this.todosData, ...data }
        this.orderTodosByEmail()
      }
    },
    twSyncOptions: {
      immediate: true,
      handler (options) {
        Object.keys(this.defaultTodoItem).forEach(key => {
          if (options[key]) this.defaultTodoItem[key] = options[key]
        })
      }
    }
  },
  methods: {
    orderTodosByEmail () {
      // group todos by email
      const groupedData = this.$options.filters.groupBy(this.todosData.todos, 'email')
      const keys = Object.keys(groupedData)

      // check everything in sort order is present in group data (having atleast a todo)
      const sortOrder = this.todosData.sortOrder.filter(key => keys.includes(key))
      // could be possible that email id of item(s) is missing in sort order
      // e.g. someone has change todos of someone to another user(s) and syncing
      // so add everything in group data gets covered
      keys.forEach(key => !sortOrder.includes(key) ? sortOrder.push(key) : true)
      this.$set(this.todosData, 'sortOrder', sortOrder)

      // group todos by email
      this.groupedTodos = []
      sortOrder.forEach(email => this.groupedTodos.push({
        email,
        items: groupedData[email].sort((a, b) => (a.otherAssignees || []).length - (b.otherAssignees || []).length)
      }))
    },

    updateSortOrder () {
      const sortOrder = this.groupedTodos.map(g => g.email)
      this.$set(this.todosData, 'sortOrder', sortOrder)
      this.$emit('data-updated', this.todosData)
    },

    addTodo () {
      this.todoFields = {}
      Object.keys(this.defaultTodoItem).forEach(key => {
        this.todoFields[key] = this.twSyncOptions[key] || this.defaultTodoItem[key]
      })
      this.showDialogTodoForm = true
    },

    editTodo (item, e) {
      if (!this.isEditable || (e && e.target.nodeName === 'A')) return
      this.todoFields = { ...this.defaultTodoItem, ...item, synced: false }
      this.showDialogTodoForm = true
    },

    updateTodoFields (todoFields, keepForNext) {
      this.todoFields = Object.assign({}, todoFields)
      this.saveTodo(keepForNext)
    },

    async saveTodo (keepForNext = false) {
      // update fields
      if (this.todoFields.id) {
        const index = this.todosData.todos.findIndex(todo => todo.id === this.todoFields.id)
        if (index === -1) return this.$emit('set-message', { type: 'error', message: 'Todo is deleted' })
        this.$set(this.todosData.todos, index, this.todoFields)
      } else {
        if (this.meetingHasStarted) this.todoFields.isNew = true
        const newTodoItem = Object.assign({
          id: this.$helpers.generateUniqueId()
        }, this.todoFields)
        this.todosData.todos.push(newTodoItem)
      }

      // add to the bottom of the list if it's a new user
      if (!this.todosData.sortOrder.includes(this.todoFields.email)) {
        this.todosData.sortOrder.push(this.todoFields.email)
      }

      const msg = `Todo ${this.todoFields.id ? 'updated' : 'added'}`
      keepForNext ? this.todoFields.todo = '' : this.closeDialogTodoForm()

      this.$emit('set-message', { type: 'success', message: msg })
      this.emitUpdate()
    },

    closeDialogTodoForm () {
      this.showDialogTodoForm = false
    },

    openDialogTodoConfirmDelete (item) {
      this.todoToDelete = item
      this.showDialogTodoConfirmDelete = true
    },

    deleteTodo () {
      const index = this.todosData.todos.findIndex(todo => todo.id === this.todoToDelete.id)
      if (index > -1) {
        this.todosData.todos.splice(index, 1)
        // remove email from sortOrder if no todos available - deleting last item
        const sortOrder = this.groupedTodos.findIndex(todo => todo.email === this.todoToDelete.email)
        if (sortOrder > -1 && this.groupedTodos[sortOrder].items.length === 1) this.todosData.sortOrder.splice(sortOrder, 1)
        this.emitUpdate()
      }

      if (this.todoToDelete.taskId) {
        this.$emit('delete-todo', this.todoToDelete.taskId)
      }

      this.closeDialogTodoConfirmDelete()
      this.closeDialogTodoForm()
      this.$emit('set-message', { type: 'success', message: 'Todo deleted' })
    },

    closeDialogTodoConfirmDelete () {
      this.showDialogTodoConfirmDelete = false
      this.todoToDelete = null
    },

    toggleDoneStatus (item) {
      if (!this.isEditable) return
      item.status = item.status === 'Done' ? 'Not done' : 'Done'
      item.synced = false
      this.emitUpdate()
    },

    emitUpdate () {
      this.$emit('data-updated', this.todosData)
    },

    logIssue (todoObj) {
      const issue = `Todo '${todoObj.todo}' off track`
      this.$emit('add-ids-issue', issue)
    }
  }
}
</script>
