<template>
  <div class="px-5">
    <div class="row">
      <div class="col-12 col-md-5 mt-4">
        <AppInputFileDrop
          v-if="isEditable && !plan.imageURL"
          :accept="['image/png', 'image/jpeg', 'image/jpg']"
          :max-file-size="3"
          :uuid="meetingId"
          :folder="planImageFolder"
          :width="imgWidth"
          :height="imgHeight"
          @upload="updatePlanImage"
        />
        <div v-else-if="plan.imageURL" class="d-flex">
          <v-img
            contain
            class="mr-3 cursor-pointer eos-meeting__plan-img"
            :width="imgWidth"
            :height="imgHeight"
            :class="imgBgColor"
            :src="imgSrc"
            @load="onImageLoad"
            @click="showLightbox = true"
          >
            <div class="d-flex fill-height justify-center align-self-center black overlay">
              <v-icon size="48px" color="transparent">mdi-magnify-plus-outline</v-icon>
            </div>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 grey lighten-2"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
          </v-img>
          <v-btn v-if="!loggedInUser.isAnonymous" icon class="ma-auto" @click="deletePlanImage">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>

        <div class="position-relative mt-5">
          <p class="mb-2">Notes</p>

          <v-btn
            v-if="!plan.notes && isEditable"
            small
            outlined
            class="d-flex mx-auto"
            @click="openDialogEditNotes"
          >Add Notes</v-btn>

          <MeetingMarkdownText
            v-else
            edit-btn-title="Plan Notes"
            :text="plan.notes"
            :is-editable="isEditable"
            @edit="openDialogEditNotes"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-5">
        <div class="mt-5">
          <p class="mb-2">Scribe</p>
          <ul class="d-flex">
            <li>
              {{ scribe | nameByEmail(attendees) }}
            </li>

            <v-tooltip v-if="isEditable" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  class="ml-auto"
                  @click="$emit('choose-random-scribe')"
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                </v-btn>
              </template>
              <span>Choose a random scibe</span>
            </v-tooltip>
          </ul>

          <div class="mt-5 d-flex align-items-center">
            <p class="mb-2">Facilitator</p>
            <v-switch
              v-model="plan.hasFacilitator"
              :ripple="false"
              color="success"
              class="mt-n1 ml-auto"
              hide-details
              @change="toggleFacilitator"
            />
          </div>
          <ul v-if="plan.hasFacilitator" class="d-flex">
            <li>
              {{ facilitator | nameByEmail(attendees) }}
            </li>

            <v-tooltip v-if="isEditable" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  class="ml-auto"
                  @click="$emit('choose-random-facilitator')"
                >
                  <v-icon>mdi-swap-horizontal</v-icon>
                </v-btn>
              </template>
              <span>Choose a random facilitator</span>
            </v-tooltip>
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-5 offset-md-1">
        <div class="mt-5">
          <p class="mb-2">Optional Meeting Sections</p>
          <ul>
            <li v-for="optionalStage in optionalStages" :key="`optional-${optionalStage.dataField}`">
              <div class="d-flex">
                <p class="mb-2">{{ optionalStage.shortName }}</p>
                <v-switch
                  v-model="optionalStage.isVisible"
                  :ripple="false"
                  color="success"
                  class="mt-n1 ml-auto"
                  hide-details
                  @change="toggleSection(optionalStage.dataField, $event)"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-5">
        <div class="d-flex">
          <span class="mb-2">Meeting Members</span>
          <v-tooltip v-if="isEditable" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                small
                top
                right
                class="ml-auto"
                @click="$emit('manage-attendees')"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Manage Attendees</span>
          </v-tooltip>
        </div>
        <ul>
          <li v-for="attendee in sortedAttendees" :key="`manage-attendee-${attendee.email}`">
            <div class="d-flex justify-space-between">
              <p class="mb-2">{{ attendee.displayName }}</p>

              <MeetingAttendeeStatus
                :attendee="attendee"
                :is-scribe="attendee.email === scribe"
                :is-facilitator="attendee.email === facilitator"
                :disabled="!isEditable"
                :can-have-facilitator="plan.hasFacilitator"
                @change-status="$emit('update-attendee-status', { email: attendee.email, status: $event })"
              />
            </div>
          </li>
        </ul>
        <v-btn
          v-if="isEditable"
          small
          depressed
          class="d-flex mx-auto mt-3 mb-8"
          @click="$emit('add-member')"
        >
          <v-icon small class="ml-n1 mr-1">mdi-plus</v-icon>
          Add new member
        </v-btn>

        <div v-if="allowSync && isEditable" class="d-flex align-center mt-5 mb-3">
          <span>Sync with <AppTeamworkBranding /></span>
          <v-chip v-if="unsyncedItemCounts > 0" class="ml-2" color="warning" small>
            {{ unsyncedItemCounts }} unsynced todo{{ unsyncedItemCounts > 1 ? 's' : '' }}
          </v-chip>

          <v-spacer />

          <AppSyncBtn
            v-if="syncOptions.accessToken && syncOptions.projectId && syncOptions.tasklistId"
            text="Sync todos"
            :syncing="syncing"
            @sync="$emit('sync-todos')"
          />
          <v-btn v-else small depressed @click="$emit('sync-with-teamwork')">
            <template v-if="syncOptions.accessToken">Select Sync Options</template>
            <template v-else>Sync Now</template>
          </v-btn>
        </div>
      </div>

      <div class="col-12 col-md-5 offset-md-1">
        <p class="black--text my-1">Set the time you want to spend on each section:</p>
        <ul class="mt-3">
          <template v-for="(stage, index) in stages">
            <li v-if="index > 0" :key="`timer-${stage.dataField}`">
              <v-row align="start">
                <v-col class="py-1">
                  <p class="my-1">{{ stage.longName }}</p>
                </v-col>
                <v-col class="d-flex flex-grow-0 py-2">
                  <v-text-field
                    :value="stage.timeInMins"
                    type="number"
                    min="1"
                    max="90"
                    outlined
                    flat
                    dense
                    hide-details
                    class="eos-meeting__section-time"
                    @change="updateTime(stage.dataField, $event)"
                  />
                </v-col>
              </v-row>
            </li>
          </template>
        </ul>
        <v-row align="center" class="mt-2">
          <v-col cols="9" class="pt-0 pb-2">
            <p class="mb-0"><b>Total Time: </b></p>
          </v-col>
          <v-col class="text-end pt-0 pb-2">
            <b>{{ totalTimeInMinutes }} mins</b>
          </v-col>
        </v-row>
      </div>
    </div>

    <MeetingDialogMarkdownEditor
      v-model="showDialogEditNotes"
      title="Edit Plan Notes"
      label="Notes"
      :text="plan.notes"
      :show-loader="isUpdatingPlan"
      @save="saveNotes"
    />

    <VueEasyLightbox
      :visible="showLightbox"
      :imgs="[imgSrc]"
      @hide="showLightbox = false"
    />
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
import AppInputFileDrop from '@/components/shared/inputs/AppInputFileDrop'
import AppTeamworkBranding from '@/components/shared/misc/AppTeamworkBranding'
import AppSyncBtn from '@/components/shared/sync/AppSyncBtn'
import MeetingMarkdownText from '@/components/meeting/MeetingMarkdownText'
import MeetingDialogMarkdownEditor from '@/components/meeting/dialogs/MeetingDialogMarkdownEditor'
import MeetingAttendeeStatus from '@/components/meeting/MeetingAttendeeStatus'

import DataManager from '@/helpers/dataManager'

export default {
  name: 'MeetingStepPlanner',
  components: {
    VueEasyLightbox,
    AppInputFileDrop,
    AppTeamworkBranding,
    AppSyncBtn,
    MeetingMarkdownText,
    MeetingDialogMarkdownEditor,
    MeetingAttendeeStatus
  },
  props: {
    stages: {
      type: Array,
      default: () => ([])
    },
    optionalStages: {
      type: Array,
      default: () => ([])
    },
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    plan: {
      type: Object,
      default: () => ({})
    },
    isUpdatingPlan: {
      type: Boolean,
      default: false
    },
    scribe: {
      type: String,
      default: ''
    },
    facilitator: {
      type: String,
      default: ''
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    syncOptions: {
      type: Object,
      default: () => ({})
    },
    allowSync: {
      type: Boolean,
      default: false
    },
    syncing: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: false
    },
    meetingId: {
      type: String,
      default: ''
    },
    unsyncedItemCounts: {
      type: Number,
      default: 0
    },
    totalTimeInMinutes: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      showDialogEditNotes: false,
      planImageFolder: 'level10s',
      imageLoaded: false,
      deletingImg: false,
      imgHeight: 200,
      showLightbox: false
    }
  },
  computed: {
    imgWidth () {
      return this.plan.imageURL ? '90%' : '100%'
    },
    sortedAttendees () {
      return this.attendees.slice().sort((a, b) => ('' + a.displayName).localeCompare(b.displayName))
    },
    imgSrc () {
      return this.deletingImg ? '' : this.plan.imageURL
    },
    imgBgColor () {
      return this.imageLoaded && !this.deletingImg ? 'white' : 'grey lighten-2'
    }
  },
  methods: {
    async onImageLoad () {
      await this.$nextTick()
      this.imageLoaded = true
    },
    openDialogEditNotes () {
      this.showDialogEditNotes = true
    },
    closeDialogEditNotes () {
      this.showDialogEditNotes = false
    },
    saveNotes (text) {
      this.$emit('data-updated', {
        ...this.plan,
        notes: text
      })
    },
    updatePlanImage (imageURL, carriedOver = false) {
      this.$emit('data-updated', {
        ...this.plan,
        imageURL: imageURL,
        carriedOver
      })
    },
    getPathStorageFromUrl (url) {
      const indexOfEndPath = url.indexOf('?')
      url = url.substring(0, indexOfEndPath)
      url = url.replace('%2F', '/')

      const paths = url.split('/')
      return `${paths[paths.length - 2]}/${paths.pop()}`
    },
    async deletePlanImage () {
      try {
        this.deletingImg = true
        // uploaded in this meeting only
        if (!this.plan.carriedOver) {
          const imgPath = this.getPathStorageFromUrl(this.plan.imageURL)
          const storageRef = this.dataManager.getStorageRef()
          await storageRef.child(imgPath).delete()
        }
        this.updatePlanImage('', true)
      } catch (error) {
        this.$emit('set-message', { type: 'error', message: error.message })
      } finally {
        this.deletingImg = false
      }
    },
    updateTime (field, value) {
      this.$emit('update-timing', { field, value })
    },
    toggleSection (field, isVisible) {
      this.$emit('toggle-section', { field, isVisible })
    },
    toggleFacilitator () {
      this.$emit('toggle-facilitator', this.plan.hasFacilitator)
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-meeting__plan-img {
  &:hover {
    opacity: 0.9;
  }

  .overlay {
    opacity: 0;
    transition: opacity .4s ease-in-out;

    &:hover {
      opacity: 0.6;

      .v-icon {
        color: white !important;
      }
    }
  }
}

::v-deep .vel-img-wrapper img {
  background: white;
}

.eos-meeting__section-time {
  ::v-deep .v-input__control,
  ::v-deep fieldset,
  ::v-deep .v-text-field__slot {
    height: 30px;
    width: 60px;
    display: block !important;
  }

  ::v-deep input {
    padding: 0px;
  }
}
</style>
