<template>
  <div class="d-flex">
    <label class="mr-2">Filter by status:</label>
    <v-menu
      open-on-hover
      bottom
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          width="105px"
          small
          depressed
          v-bind="attrs"
          v-on="on"
        >
          {{ selectedFiltersCount }} selected
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="status in statuses"
          :key="`status-filter-${status.id}`"
          :disabled="(status.count / 1)  === 0"
          dense
          @click="toggleStatus(status.id)"
        >
          <v-list-item-title>
            <v-icon :disabled="(status.count / 1)  === 0" size="16px" class="mr-1">
              {{ selectedAll || value.includes(status.id) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
            </v-icon>
            {{ status.text }} ({{ status.count }})
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'KeyPrioritiesFilterStatus',
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    statuses: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    selectedFiltersCount () {
      return this.value.length
    },
    selectedAll () {
      return this.selectedFiltersCount === this.statuses.length
    }
  },
  mounted () {
    this.selectAllStatus()
  },
  methods: {
    ...mapActions(['setMessage']),
    selectAllStatus () {
      this.$emit('input', this.statuses.filter(status => status.count > 0).map(status => status.id))
    },
    toggleStatus (status) {
      const currFilters = this.value.slice()
      const index = currFilters.indexOf(status)
      index > -1 ? currFilters.splice(index, 1) : currFilters.push(status)

      if (currFilters.length === 0) {
        return this.setMessage({ type: 'error', message: 'At least a status must be selected' })
      }

      this.$emit('input', currFilters)
    }
  }
}
</script>
