<template>
  <EOSScorecard
    mode="level10"
    :initial-view="scorecardData.initialView"
    :initial-metrics="scorecardData.metrics"
    :initial-monthly-metrics="scorecardData.monthlyMetrics"
    :initial-score-dates="scorecardData.scoreDates"
    :initial-hidden-columns="scorecardData.hiddenColumns"
    :is-editable="isEditable"
    @metric-update="updateMetrics"
    @add-ids-issue="addIDSIssue"
    @change-view="changeInitialView"
    @set-message="$emit('set-message', $event)"
  />
</template>

<script>
import EOSScorecard from '@/components/eos/EOSScorecard'

export default {
  name: 'MeetingStepScorecard',
  components: {
    EOSScorecard
  },
  props: {
    initialScorecardData: {
      type: Object,
      default: () => ({})
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      scorecardData: {
        metrics: [],
        monthlyMetrics: [],
        scoreDates: [],
        hiddenColumns: {
          weekly: [],
          monthly: []
        },
        initialView: ''
      }
    }
  },
  watch: {
    initialScorecardData: {
      immediate: true,
      handler (data) {
        this.scorecardData = { ...this.scorecardData, ...data }
      }
    }
  },
  methods: {
    changeInitialView (view) {
      this.$set(this.scorecardData, 'initialView', view) // weekly/monthly
      if (this.isEditable) this.$emit('data-updated', this.scorecardData)
    },
    updateMetrics (scorecard) {
      if (!this.isEditable) return
      this.scorecardData.metrics = scorecard.metrics
      this.scorecardData.monthlyMetrics = scorecard.monthlyMetrics
      this.scorecardData.scoreDates = scorecard.scoreDates
      this.scorecardData.hiddenColumns = scorecard.hiddenColumns
      this.$emit('data-updated', this.scorecardData)
    },
    addIDSIssue (metricAndIssue) {
      this.$emit('add-ids-issue', metricAndIssue)
    }
  }
}
</script>
