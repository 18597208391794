import { render, staticRenderFns } from "./KeyPriorityDialogForm.vue?vue&type=template&id=59554fdf&scoped=true&"
import script from "./KeyPriorityDialogForm.vue?vue&type=script&lang=js&"
export * from "./KeyPriorityDialogForm.vue?vue&type=script&lang=js&"
import style0 from "./KeyPriorityDialogForm.vue?vue&type=style&index=0&id=59554fdf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59554fdf",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardText,VCardTitle,VContainer,VDialog,VDivider,VFlex,VLayout,VSelect,VSpacer,VTextarea})
