<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <span class="ml-1" v-on="on">+ {{ otherAssignees.length }} other</span>
    </template>
    <ul class="pl-0" style="list-style: none;">
      <li
        v-for="email in otherAssignees"
        :key="`${uniqueId}-${email}`"
      >
        {{ email | nameByEmail(attendees) }}
      </li>
    </ul>
  </v-tooltip>
</template>

<script>
export default {
  name: 'MeetingListOtherAssignees',
  props: {
    uniqueId: {
      type: String,
      required: true
    },
    attendees: {
      type: Array,
      required: true
    },
    otherAssignees: {
      type: Array,
      required: true
    }
  }
}
</script>
