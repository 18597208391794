var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('KeyPriorityDialogForm',{attrs:{"title":_vm.rockFields.parentRockId ? 'Deliverable' : 'Key Priority',"users":_vm.attendees,"fields":_vm.rockFields,"manage-users-btn-text":"Manage Attendees"},on:{"manage-users":function($event){return _vm.$emit('manage-attendees')},"save":function($event){return _vm.updateRockFields($event, false)},"save-next":function($event){return _vm.updateRockFields($event, true)},"delete":_vm.openDialogRockConfirmDelete,"set-message":function($event){return _vm.$emit('set-message', $event)},"close":_vm.closeDialogRockForm},model:{value:(_vm.showDialogRockForm),callback:function ($$v) {_vm.showDialogRockForm=$$v},expression:"showDialogRockForm"}}),(_vm.showDialogRockConfirmDelete)?_c('AppDialogConfirmDelete',{attrs:{"message":("You want to delete this " + (_vm.rockToDelete.parentRockId ? 'deliverable' : 'key priority') + "?")},on:{"confirm":_vm.deleteRock,"cancel":_vm.closeDialogRockConfirmDelete}}):_vm._e(),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('small',[_vm._v("Key Priorities")])]),_c('v-spacer'),_c('KeyPrioritiesFilterStatus',{attrs:{"statuses":_vm.rockStatuses},model:{value:(_vm.selectedStatusFilters),callback:function ($$v) {_vm.selectedStatusFilters=$$v},expression:"selectedStatusFilters"}}),(_vm.isEditable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.addRock()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3083551690)},[_c('span',[_vm._v("Add Key Priority")])]):_vm._e()],1),(_vm.hasRocks)?_c('div',{staticClass:"v-data-table theme--light"},[_c('div',{staticClass:"v-data-table__wrapper"},[_c('draggable',_vm._b({attrs:{"tag":"table"},on:{"change":_vm.updateSortOrder},model:{value:(_vm.groupedRocks),callback:function ($$v) {_vm.groupedRocks=$$v},expression:"groupedRocks"}},'draggable',Object.assign({}, _vm.dragOptions,
          {disabled: _vm.disableDrag}),false),_vm._l((_vm.groupedRocks),function(group,groupIndex){return _c('tbody',{key:groupIndex},[(groupIndex > 0)?_c('tr',[_c('td',{staticStyle:{"height":"1px"},attrs:{"colspan":"5"}})]):_vm._e(),_vm._l((_vm.filteredRocks(group.items)),function(item,index){return [_c('KeyPriorityListItem',{key:(groupIndex + "-" + index),class:{ 'draggable-list-item': !_vm.disableDrag },attrs:{"users":_vm.attendees,"item":item,"is-expanded":_vm.expandedItemIds.includes(item.id),"is-editable":_vm.isEditable,"with-title":index === 0,"with-type":"","with-deliverables":""},on:{"toggle":_vm.toggleDetails,"edit":_vm.editRock,"update-date":_vm.updateDate,"delete":_vm.openDialogRockConfirmDelete}},[_c('div',{staticClass:"d-flex align-center",attrs:{"slot":"actions"},slot:"actions"},[(_vm.userRocktools.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.linkWithRocktool(item.id)}}},on),[_vm._v("mdi-bullseye-arrow")])]}}],null,true)},[_c('span',[_vm._v("Link with Key Priorities")])]):_vm._e(),(_vm.userVTOs.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.linkWithVTO(item.id)}}},on),[_vm._v("mdi-binoculars")])]}}],null,true)},[_c('span',[_vm._v("Link with Vision & Plan")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.addRock(item)}}},on),[_vm._v("mdi-plus")])]}}],null,true)},[_c('span',[_vm._v("Add Deliverable")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.logIssue(item)}}},on),[_vm._v("mdi-alert-octagon-outline")])]}}],null,true)},[_c('span',[_vm._v("Log an issue")])])],1)]),_c('v-expand-transition',{key:("keypriority-details-" + index)},[_c('KeyPriorityListItemDetails',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandedItemIds.includes(item.id)),expression:"expandedItemIds.includes(item.id)"}],attrs:{"description":item.description},on:{"edit-desc":function (e) { return _vm.editRock({ item: item, e: e }); }}},[(item.deliverables.length > 0)?_c('div',{staticClass:"pl-3 pb-3 grey lighten-4",attrs:{"slot":"deliverables"},slot:"deliverables"},[_c('div',{staticClass:"v-data-table theme--light"},[_c('div',{staticClass:"v-data-table__wrapper grey lighten-4"},[_c('draggable',_vm._b({staticStyle:{"width":"100%"},attrs:{"tag":"table"},on:{"end":_vm.emitUpdate},model:{value:(item.deliverables),callback:function ($$v) {_vm.$set(item, "deliverables", $$v)},expression:"item.deliverables"}},'draggable',{
                          ghostClass: 'ghost',
                          disabled: item.deliverables.length < 2
                        },false),_vm._l((item.deliverables),function(dl,deliverableIndex){return _c('tbody',{key:("key-priority-dl-" + deliverableIndex),on:{"edit-desc":function (e) { return _vm.editRock({ item: item, e: e }); }}},[_c('KeyPriorityListItem',{staticClass:"white",attrs:{"users":_vm.attendees,"item":dl,"is-expanded":_vm.expandedItemIds.includes(dl.id),"with-title":""},on:{"toggle":_vm.toggleDetails,"edit":_vm.editRock,"update-date":_vm.updateDate,"delete":_vm.openDialogRockConfirmDelete}}),_c('KeyPriorityListItemDetails',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandedItemIds.includes(dl.id)),expression:"expandedItemIds.includes(dl.id)"}],staticClass:"white",attrs:{"description":dl.description,"persistent-description":""},on:{"edit-desc":function (e) { return _vm.editRock({ dl: dl, e: e }); }}})],1)}),0)],1)])]):_vm._e()])],1)]})],2)}),0)],1)]):_vm._e(),(_vm.isEditable)?[(_vm.hasRocks)?_c('div',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"ma-3",attrs:{"depressed":"","small":""},on:{"click":function($event){return _vm.addRock()}}},[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":""}},[_vm._v("mdi-plus")]),_vm._v("Add Key Priority ")],1)],1):_c('v-btn',{staticClass:"d-flex mt-3 mx-auto",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.addRock()}}},[_vm._v("Add Key Priority")])]:(!_vm.hasRocks)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("No key priority found")])]):_vm._e(),(_vm.showLinksDialog)?_c('KeyPriorityDialogLinks',{attrs:{"fetching-items":_vm.isFetchingLinkedItems,"show-loader":_vm.isUpdatingLinks,"title":_vm.linkDialogTitle,"data":_vm.linkDialogData,"selected-ids":_vm.selectedIds},on:{"update-relation":_vm.updateKeyPriorityRelations,"close":_vm.closeLinksDialog}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }