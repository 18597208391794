<template>
  <v-card class="mx-auto mt-5" max-width="400">
    <v-img
      :src="coverImg"
      width="400"
      height="250"
      class="white--text align-end"
    >
      <v-card-title>Meeting has ended</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">
      <v-chip class="mr-2" dark>{{ todos.length }} Tasks</v-chip>
      <v-chip v-if="!hideRating" class="mr-2" :color="avgRating >= 7 ? 'success' : 'error'" dark>
        Rating: {{ avgRating }}/10
        <v-icon right v-if="avgRating >= 7">mdi-trophy-award</v-icon>
      </v-chip>
      <v-btn
        rounded
        depressed
        height="32px"
        @click="$emit('view-details')"
      >View again</v-btn>
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <div
        class="mt-5 eos-meeting__summary"
      >
        <ul>
          <li v-for="(todo, index) in todos" :key="`meeting-todo-${index}`">
            {{ todo.email | nameByEmail(attendees) }}: {{ todo.todo }}
          </li>
          <li v-if="todos.length === 0">No tasks this week</li>
        </ul>
      </div>
    </v-card-text>

    <v-card-actions v-if="!hasNextMeeting" class="d-flex flex-column">
      <v-checkbox
        v-model="sendMeetingSummary"
        :ripple="false"
        color="primary"
        class="mt-0 align-self-start"
        label="Send out summary email"
        hide-details
      />
      <v-btn
        :loading="showLoader"
        block
        large
        dark
        class="mt-2"
        @click="$emit('setup-next-meeting', sendMeetingSummary)"
      >{{ btnText }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'MeetingSummary',
  props: {
    todos: {
      type: Array,
      default: () => ([])
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    avgRating: {
      type: Number,
      default: 10
    },
    hideRating: {
      type: Boolean,
      default: false
    },
    hasNextMeeting: {
      type: Boolean,
      default: false
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sendMeetingSummary: true
    }
  },
  computed: {
    coverImg () {
      return require(`@/assets/finished-l10/${Math.ceil(Math.random() * 7)}.jpg`)
    },
    btnText () {
      let txt = 'Set-up next meeting'
      if (this.sendMeetingSummary) txt += ' + Send summary'
      return txt
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-meeting__summary {
  min-height: 100px;
  max-height: calc(100vh - 550px);
  overflow-y: auto;
}
</style>
