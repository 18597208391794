<template>
  <v-dialog :value="value" persistent scrollable max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
        <v-spacer />
        <v-btn
          text
          small
          color="primary"
          class="ma-0"
          @click="$emit('manage-users')"
        >{{ manageUsersBtnText }}</v-btn>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-alert v-if="twSyncOptions.accessToken && rockFields.projectId && !assigneeHandle" color="error" outlined class="text-body-2 px-2 pt-1 pb-2">
          <small>
            Please make sure the email address <strong>{{ rockFields.email }}</strong> is registered with teamwork.
            Otherwise, the key priority will get assgined to <strong>Guest User</strong> in the task list.
          </small>
        </v-alert>
        <form @submit.prevent="save(false)">
          <v-container pa-0 ma-0 mt-5 grid-list-md fluid fill-height>
            <v-layout pa-0 ma-0 row>
              <v-flex pa-0 ma-0 sm7>
                <v-select
                  v-model.trim="rockFields.email"
                  :items="users"
                  item-value="email"
                  item-text="displayName"
                  label="Who"
                  class="pr-1"
                  outlined
                />
              </v-flex>
              <v-flex pa-0 ma-0 sm5>
                <v-select
                  v-model="rockFields.type"
                  :items="['Company', 'Team', 'Functional', 'Personal']"
                  label="Type"
                  class="pl-1"
                  outlined
                />
              </v-flex>
            </v-layout>
          </v-container>

          <v-textarea
            v-model.trim="rockFields.rock"
            class="pb-0 mb-5"
            rows="1"
            :label="rockFields.parentRockId ? 'Deliverable' : 'Key Priority'"
            outlined
            hide-details
            autofocus
            auto-grow
          />

          <v-container
            v-if="rockFields.hasOwnProperty('startDate') && rockFields.hasOwnProperty('endDate')"
            px-0
            pt-0
            pb-8
            grid-list-md
            fluid
            fill-height
          >
            <v-layout pa-0 ma-0 row justify-space-between class="eos-keypriorities__datepicker">
              <v-flex pl-0 sm6>
                <p class="mb-1 text-caption">Start Date</p>
                <AppDatePicker
                  default-text="Select Date"
                  square
                  :date="rockFields.startDate"
                  @change="rockFields.startDate = $event"
                />
              </v-flex>
              <v-flex pr-0 sm6>
                <p class="mb-1 text-caption">End Date</p>
                <AppDatePicker
                  default-text="Select Date"
                  square
                  :date="rockFields.endDate"
                  @change="rockFields.endDate = $event"
                />
              </v-flex>
            </v-layout>
          </v-container>

          <v-textarea
            v-model.trim="rockFields.description"
            label="Description"
            class="pb-0 mb-5"
            rows="4"
            outlined
            hide-details
            auto-grow
          />

          <v-container px-0 ma-0 grid-list-md fluid fill-height>
            <v-layout pa-0 ma-0 row>
              <v-flex pa-0 ma-0 :sm7="rockFields.status !== 'Complete'">
                <v-select
                  v-model="rockFields.status"
                  :items="rockStatuses"
                  label="Status"
                  class="pr-1"
                  outlined
                  hide-details
                />
              </v-flex>
              <v-flex v-if="rockFields.status !== 'Complete'" pa-0 ma-0 sm5>
                <v-select
                  v-model="rockFields.progress"
                  :items="[
                    { value: 0, text: '0%' },
                    { value: 10, text: '10%' },
                    { value: 20, text: '20%' },
                    { value: 30, text: '30%' },
                    { value: 40, text: '40%' },
                    { value: 50, text: '50%' },
                    { value: 60, text: '60%' },
                    { value: 70, text: '70%' },
                    { value: 80, text: '80%' },
                    { value: 90, text: '90%' },
                    { value: 99.99, text: '99.99%' }
                  ]"
                  label="Progress"
                  class="pl-1"
                  outlined
                  hide-details
                  @change="$event === 99.99 ? $emit('set-message', {
                    type: 'success',
                    message: 'Really! :p'
                  }) : null"
                />
              </v-flex>
            </v-layout>

            <v-layout v-if="!!twSyncOptions.accessToken" pa-0 ma-0 row class="d-flex flex-column">
              <p class="mt-8 black--text">Sync with Teamwork Tasks</p>
              <v-select
                v-model="rockFields.projectId"
                :menu-props="{ offsetY: true, maxWidth: 363 }"
                :items="projects"
                item-value="id"
                item-text="name"
                label="Project"
                class="pr-1"
                outlined
                hide-details
                @change="fetchTasklists"
              />

              <v-select
                v-model="rockFields.tasklistId"
                :menu-props="{ offsetY: true, maxWidth: 363 }"
                :loading="fetchingTasklists"
                :disabled="projects.length === 0"
                :items="tasklists"
                item-value="id"
                item-text="name"
                label="Tasklist"
                class="pr-1 mt-5"
                outlined
                hide-details
              />
            </v-layout>
          </v-container>
        </form>
      </v-card-text>

      <v-divider />
      <v-card-actions class="mt-1">
        <v-btn
          v-if="rockFields.id"
          color="error"
          class="ml-2 mb-2"
          text
          @click="$emit('delete', rockFields)"
        >Delete</v-btn>
        <v-spacer />
        <v-btn text class="mb-2" @click="close">Cancel</v-btn>
        <v-btn
          v-if="!rockFields.id"
          class="mr-2 mb-2"
          depressed
          @click="validateAndSave(true)"
        >Save + Next</v-btn>
        <v-btn color="success" class="mr-2 mb-2" :loading="saving" @click="validateAndSave(false)">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AppDatePicker from '@/components/shared/datepickers/AppDatePicker'
import KEY_PRIORITIES_STATUSES from '@/enums/keyPriorityStatuses'

export default {
  name: 'KeyPriorityDialogForm',
  components: { AppDatePicker },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    twProjects: {
      type: Array,
      default: () => ([])
    },
    twTasklists: {
      type: Array,
      default: () => ([])
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    users: {
      type: Array,
      default: () => ([])
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    manageUsersBtnText: {
      type: String,
      default: 'Manage Users'
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rockFields: {
        email: '',
        projectId: null,
        tasklistId: null,
        taskId: null,
        rock: '',
        type: 'Company',
        status: KEY_PRIORITIES_STATUSES.ON_TRACK,
        progress: 0
      },
      projects: [],
      tasklists: [],
      rockStatuses: Object.values(KEY_PRIORITIES_STATUSES),
      fetchingTasklists: false
    }
  },
  computed: {
    formTitle () {
      const prefix = this.rockFields.id ? 'Edit' : 'Add'
      return `${prefix} ${this.title}`
    },
    assigneeHandle () {
      const user = this.users.find(user => user.email === this.rockFields.email) || {}
      return user.handle || ''
    }
  },
  watch: {
    fields: {
      immediate: true,
      handler (fields) {
        delete fields.groupId
        delete this.rockFields.id
        if (!fields.id) {
          delete fields.taskId
        }

        // set to defaults
        this.projects = this.twProjects.slice()
        this.tasklists = this.twTasklists.slice()

        this.rockFields = { ...this.rockFields, ...fields }

        // type casting to avoid comparision issues
        this.rockFields.projectId = this.rockFields.projectId ? this.rockFields.projectId.toString() : null
        this.rockFields.tasklistId = this.rockFields.tasklistId ? this.rockFields.tasklistId.toString() : null
        // fetch tasklist if selected diff tasklists
        if (this.rockFields.projectId !== this.twSyncOptions.projectId) this.fetchTasklists(fields.projectId)
      }
    },
    twTasklists (tasklists) {
      this.fetchingTasklists = false
      this.tasklists = tasklists.slice()
    },
    'rockFields.startDate': {
      handler (date) {
        if (!date) return // no start date

        // end date should be same if no end date selected
        if (!this.rockFields.endDate) {
          this.rockFields.endDate = date
          return
        }

        // end date should be >= start date
        const diffTime = new Date(this.rockFields.endDate) - new Date(date)
        if (diffTime < 0) this.rockFields.endDate = date
      }
    },
    'rockFields.endDate': {
      handler (date) {
        if (!date) return // no end date

        // start date should be same if no start date selected
        if (!this.rockFields.startDate) {
          this.rockFields.startDate = date
          return
        }

        // end date should be >= start date
        const diffTime = new Date(date) - new Date(this.rockFields.startDate)
        if (diffTime < 0) this.rockFields.startDate = date
      }
    }
  },
  methods: {
    fetchTasklists (projectId) {
      this.fetchingTasklists = !!this.rockFields.projectId
      this.$emit('refresh-tasklist', projectId)
    },
    validateAndSave (keepOpenForNext) {
      // validate
      if (!this.rockFields.email || !this.rockFields.rock) {
        return this.$emit('set-message', { type: 'error', message: 'Fill in the fields' })
      }

      const evtName = keepOpenForNext ? 'save-next' : 'save'
      this.$emit(evtName, this.rockFields)
    },
    close () {
      if (this.rockFields.projectId !== this.twSyncOptions.projectId) this.fetchTasklists()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-keypriorities__datepicker {
  ::v-deep .v-chip {
    width: 100%;
    margin: 0px !important;

    &:not(:hover) {
      border-color: #a1a1a1 !important;
    }
  }
}
</style>
