<template>
  <v-sheet
    ref="dzone"
    tabindex="0"
    rounded="lg"
    class="cursor-pointer dzone"
    :width="width"
    :height="height"
    :color="dragover ? 'grey lighten-2' : 'grey lighten-4'"
    @click="clickFileInput"
    @keypress="clickFileInput"
    @dragenter.prevent="onDragStart"
    @dragleave.prevent="onDragEnd"
    @dragover.prevent
    @drop.prevent="filesSelected"
  >
    <div class="d-flex flex-column justify-center align-center fill-height dzone-container">
      <!-- Upload Icon -->
      <template v-if="files.length === 0">
        <div>
          <v-icon size="75">mdi-cloud-upload-outline</v-icon>
        </div>
        <p class="subtitle-1 text--secondary">Upload or drag and drop an image</p>
      </template>

      <!-- Indicate selected files -->
      <div v-else class="input-container">
        <template v-for="(item, index) in files">
          <v-input
            :key="`file-${item.file.name}-${index}`"
            hide-details
            append-icon="mdi-close"
            prepend-icon="mdi-file"
            @click:append="removeFile(item.file, index)"
          >
            {{ item.file.name }}
          </v-input>

          <div style="min-height: 4px;" :key="`progress-${item.file.name}-${index}`">
            <v-progress-linear v-model="item.progress" />
          </div>
        </template>
      </div>
    </div>

    <!-- Hidden upload button to bring up file selection dialog -->
    <input
      ref="filebtn"
      class="file-btn"
      type="file"
      :multiple="multiple"
      :accept="accept"
      @input="filesSelected"
    />
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataManager from '@/helpers/dataManager'

export default {
  name: 'AppInputFileDrop',
  props: {
    accept: {
      type: Array,
      default: () => ([])
    },
    multiple: {
      type: Boolean,
      default: false
    },
    maxFileSize: {
      type: Number, // in MB
      default: null
    },
    height: {
      type: [Number, String],
      default: '100%'
    },
    width: {
      type: [Number, String],
      default: '100%'
    },
    folder: {
      type: String,
      default: ''
    },
    uuid: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dataManager: new DataManager(),
      dragover: false,
      files: [],
      uploads: [],
      tasksInProgress: []
    }
  },
  computed: {
    ...mapGetters(['loggedInUser'])
  },
  methods: {
    ...mapActions(['setMessage']),
    onDragStart () {
      this.dragover = true
    },
    onDragEnd () {
      this.dragover = false
    },
    clickFileInput () {
      this.$refs.filebtn.click()
    },
    async filesSelected (e) {
      if (this.loggedInUser.isAnonymous) {
        return this.setMessage({ type: 'error', message: 'You must be logged to upload the file' })
      }

      const droppedFiles = e.target.files || e.dataTransfer.files
      if (!droppedFiles) return

      this.dragover = false
      // FileList => Array of Files conversion
      const files = ([...droppedFiles])

      // Validate file type
      if (this.accept) {
        let text = ''
        for (let i = 0; i < files.length; i++) {
          if (this.accept.includes(files[i].type)) continue
          text += `Invalid file ${files[i].name}. Only ${this.accept.join(',')} files allowed`
          break
        }
        if (text) return this.setMessage({ type: 'error', message: text })
      }

      // Validate file size
      if (this.maxFileSize) {
        const limit = Math.floor(this.maxFileSize * 1024 * 1024) // MB
        let text = ''
        // Check against all files
        for (let i = 0; i < files.length; i++) {
          if (files[i].size <= limit) continue
          text += `${files[i].name} exceed the limit of ${this.maxFileSize} MB`
          break
        }
        if (text) return this.setMessage({ type: 'error', message: text })
      }

      // Push file(s) to list
      this.files = !this.multiple && files.length > 1 ? [{ file: files[0], progress: 0 }] : files.map(f => ({ file: f, progress: 0 }))

      await this.$nextTick()
      this.$refs.filebtn.value = ''

      this.uploadFiles()
    },
    removeFile (file, index) {
      this.files = this.files.filter(f => f.file !== file)
      if (!this.tasksInProgress[index]) return
      this.tasksInProgress[index].cancel()
    },
    async uploadFiles () {
      const promises = []

      // iterate through each files
      for (let i = 0; i < this.files.length; i++) {
        promises.push(new Promise((resolve, reject) => {
          // create a storage reference
          const storageRef = this.dataManager.getStorageRef()
          const file = this.files[i].file
          const metadata = {
            contentType: file.type,
            customMetadata: {
              uploadedBy: this.loggedInUser.uid
            }
          }

          // Upload file and metadata to the object
          const fName = `${this.uuid}_${this.$helpers.generateUniqueId()}_${file.name}`
          const uploadTask = storageRef.child(`${this.folder}/${fName}`).put(file, metadata)
          this.tasksInProgress.push(uploadTask)

          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on('state_changed',
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              this.$set(this.files[i], 'progress', progress)
            },
            (error) => {
              switch (error.code) {
                // User doesn't have permission to access the object
                case 'storage/unauthorized':
                  reject(error)
                  this.setMessage({ type: 'error', message: 'Invalid file type/size or insufficient permission.' })
                  break
                // User canceled the upload
                case 'storage/canceled':
                  this.removeFile(this.files[i])
                  this.tasksInProgress.splice(i, 0)
                  promises.splice(i, 1)
                  break
                default:
                  reject(error)
                  this.setMessage({ type: 'error', message: error.message })
              }
            },
            async () => {
              // Upload completed successfully, now we can get the download URL
              const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
              resolve(downloadURL)
            })
        }))
      }

      const data = await Promise.all(promises)
      this.$emit('upload', this.multiple ? data : data[0])
      this.tasksInProgress = []
    }
  }
}
</script>

<style lang="scss" scoped>
.dzone {
  outline: none;
}

.dzone-container {
  border: 1px dashed #BDBDBD;
  border-radius: 8px;
}

div.input-container {
  min-width: 50%;
}

.v-input {
  ::v-deep div.v-input__control {
    div.v-input__slot {
      margin-top: 4px;
      margin-bottom: 0 !important;
    }
  }
}

input.file-btn {
  display: none;
}
</style>
