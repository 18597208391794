<template>
  <div>
    <v-dialog v-model="showDialogHeadlineForm" persistent max-width="400px">
      <form @submit.prevent="saveHeadline(false)">
        <v-card>
          <v-card-title>
            <span class="headline">{{ headlineFormTitle }}</span>
            <v-spacer />
            <v-btn
              class="ma-0 pa-0"
              color="primary"
              text
              small
              @click="$emit('manage-attendees')"
            >Manage attendees</v-btn>
          </v-card-title>

          <v-card-text>
            <v-select
              v-model="headlineFields.email"
              :items="attendees"
              item-value="email"
              item-text="displayName"
              label="Who"
              outlined
              class="mt-5"
            />
            <v-textarea
              v-model="headlineFields.headline"
              label="Headline"
              rows="2"
              class="pb-0 mb-0"
              auto-grow
              outlined
              autofocus
              hide-details
            />
          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="headlineFields.id"
              color="error"
              class="ml-2 mb-2"
              text
              @click="openDialogHeadlineConfirmDelete(headlineFields)"
            >Delete</v-btn>
            <v-spacer />
            <v-btn text class="mb-2" @click="closeDialogHeadlineForm">Cancel</v-btn>
            <v-btn
              v-if="!headlineFields.id"
              depressed
              class="mr-2 mb-2"
              @click="saveHeadline(true)"
            >Save + Next</v-btn>
            <v-btn color="success" class="mr-2 mb-2" @click="saveHeadline(false)">Save</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <AppDialogConfirmDelete
      v-if="showDialogHeadlineConfirmDelete"
      message="You want to delete this headline?"
      @confirm="deleteHeadline"
      @cancel="closeDialogHeadlineConfirmDelete"
    />

    <v-toolbar flat dense>
      <v-toolbar-title>
        <small>Headlines</small>
      </v-toolbar-title>

      <v-spacer />

      <v-tooltip v-if="isEditable" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="addHeadline">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Headline</span>
      </v-tooltip>
    </v-toolbar>

    <div v-if="hasHeadlines"  class="v-data-table theme--light">
      <div class="v-data-table__wrapper">
        <draggable
          v-model="groupedHeadlines"
          v-bind="{
            ...dragOptions,
            disabled: disableDrag
          }"
          tag="table"
          @change="updateSortOrder"
        >
          <tbody v-for="(group, groupIndex) in groupedHeadlines" :key="groupIndex">
            <tr v-if="groupIndex > 0"><td colspan="3" style="height: 1px;"></td></tr>
            <tr
              v-for="(item, index) in group.items"
              :key="`${groupIndex}-${index}`"
              :class="{ 'draggable-list-item': !disableDrag }"
            >
              <td style="width: 200px;">
                <div class="text-no-wrap">
                  <strong v-if="index === 0">{{ item.email | nameByEmail(attendees) }}</strong>
                </div>
              </td>
              <td>
                <div
                  class="py-1 eos-tbl__content-grow"
                  :class="{ 'cursor-pointer': isEditable }"
                  v-html="$options.filters.nlToList(item.headline)"
                  @click="editHeadline(item, $event)"
                >
                </div>
              </td>
              <td v-if="isEditable" style="width: 50px;">
                <div class="d-flex justify-end mr-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small class="mr-2" @click="logIssue(item)">mdi-alert-octagon-outline</v-icon>
                    </template>
                    <span>Log an issue</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small class="mr-2" @click="editHeadline(item)">mdi-pencil</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small @click="openDialogHeadlineConfirmDelete(item)">mdi-delete</v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </draggable>
      </div>
    </div>

    <template v-if="isEditable">
      <div v-if="hasHeadlines" class="pa-0">
        <v-btn depressed small class="ma-3" @click="addHeadline">
          <v-icon x-small class="pr-2">mdi-plus</v-icon>Add Headline
        </v-btn>
      </div>
      <v-btn v-else small outlined class="d-flex mt-3 mx-auto" @click="addHeadline">Add the first headline</v-btn>
    </template>
    <div v-else-if="!hasHeadlines" class="text-center">
      <p>No headline found</p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'MeetingStepHeadlines',
  components: { draggable },
  props: {
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    initialHeadlinesData: {
      type: Object,
      default: () => ({})
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    dragOptions: {
      type: Object,
      default: () => ({})
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      headlineData: {
        sortOrder: [],
        headlines: []
      },
      showDialogHeadlineForm: false,
      showDialogHeadlineConfirmDelete: false,
      headlineFields: {
        email: '',
        headline: ''
      },
      defaultHeadline: {
        email: this.loggedInUser.email,
        headline: ''
      },
      headlineToDelete: null,
      groupedHeadlines: []
    }
  },
  computed: {
    headlineFormTitle () {
      return `${(this.headlineFields.id ? 'Edit' : 'New')}  Headline`
    },
    disableDrag () {
      return this.headlineData.sortOrder.length < 2 || !this.isEditable
    },
    hasHeadlines () {
      return this.headlineData.headlines.length > 0
    }
  },
  watch: {
    initialHeadlinesData: {
      immediate: true,
      handler (data) {
        this.headlineData = { ...this.headlineData, ...data }
        this.headlineData.headlines.forEach(headline => {
          headline.id = headline.id || this.$helpers.generateUniqueId()
        })

        this.headlineData.headlines.sort((a, b) => {
          // compare first name / email
          if (a.email !== b.email) {
            const nameA = this.$options.filters.nameByEmail(a.email, this.attendees)
            const nameB = this.$options.filters.nameByEmail(b.email, this.attendees)
            return ('' + nameA).localeCompare(nameB)
          }
          // compare headline
          return ('' + a.headline).localeCompare(b.headline)
        })

        this.orderHeadlinesByEmail()
      }
    }
  },
  methods: {
    orderHeadlinesByEmail () {
      // group headlines by email
      const groupedData = this.$options.filters.groupBy(this.headlineData.headlines, 'email')
      // add sort order attr if not present
      if (this.headlineData.sortOrder.length === 0) this.$set(this.headlineData, 'sortOrder', Object.keys(groupedData))
      // group headlines by email
      this.groupedHeadlines = []
      this.headlineData.sortOrder.forEach(email => this.groupedHeadlines.push({
        email,
        items: groupedData[email]
      }))
    },

    updateSortOrder () {
      const sortOrder = this.groupedHeadlines.map(g => g.email)
      this.$set(this.headlineData, 'sortOrder', sortOrder)
      this.$emit('data-updated', this.headlineData)
    },

    addHeadline () {
      this.headlineFields = Object.assign({}, this.defaultHeadline)
      this.showDialogHeadlineForm = true
    },

    editHeadline (item, e) {
      if (!this.isEditable || (e && e.target.nodeName === 'A')) return
      this.headlineFields = Object.assign({}, item)
      this.showDialogHeadlineForm = true
    },

    saveHeadline (keepForNext = false) {
      // validate
      if (!this.headlineFields.email || !this.headlineFields.headline) {
        return this.$emit('set-message', { type: 'error', message: 'Fill in the fields' })
      }

      // update fields
      if (this.headlineFields.id) {
        const index = this.headlineData.headlines.findIndex(headline => headline.id === this.headlineFields.id)
        if (index === -1) return this.$emit('set-message', { type: 'error', message: 'Headline is deleted' })
        this.$set(this.headlineData.headlines, index, this.headlineFields)
      } else {
        const newHeadlineItem = Object.assign({
          id: this.$helpers.generateUniqueId()
        }, this.headlineFields)
        this.headlineData.headlines.push(newHeadlineItem)
      }

      // add to the bottom of the list if it's a new user
      if (!this.headlineData.sortOrder.includes(this.headlineFields.email)) {
        this.headlineData.sortOrder.push(this.headlineFields.email)
      }

      const msg = `Headline ${this.headlineFields.id ? 'updated' : 'added'}`
      keepForNext ? this.headlineFields.headline = '' : this.closeDialogHeadlineForm()

      this.$emit('set-message', { type: 'success', message: msg })
      this.$emit('data-updated', this.headlineData)
    },

    closeDialogHeadlineForm () {
      this.showDialogHeadlineForm = false
    },

    openDialogHeadlineConfirmDelete (item) {
      this.headlineToDelete = item
      this.showDialogHeadlineConfirmDelete = true
    },

    deleteHeadline () {
      const index = this.headlineData.headlines.findIndex(headline => headline.id === this.headlineToDelete.id)
      if (index > -1) {
        this.headlineData.headlines.splice(index, 1)
        // remove email from sortOrder if no headlines available - deleting last item
        const sortOrder = this.groupedHeadlines.findIndex(headline => headline.email === this.headlineToDelete.email)
        if (sortOrder > -1 && this.groupedHeadlines[sortOrder].items.length === 1) this.headlineData.sortOrder.splice(sortOrder, 1)
        this.$emit('data-updated', this.headlineData)
      }

      this.closeDialogHeadlineConfirmDelete()
      this.closeDialogHeadlineForm()
      this.$emit('set-message', { type: 'success', message: 'Headline deleted' })
    },

    closeDialogHeadlineConfirmDelete () {
      this.showDialogHeadlineConfirmDelete = false
      this.headlineToDelete = null
    },

    logIssue (headlineObj) {
      const issue = `Headline '${headlineObj.headline}' off track`
      this.$emit('add-ids-issue', issue)
    }
  }
}
</script>
