<template>
  <div>
    <v-dialog v-model="showDialogNoteForm" persistent max-width="400px">
      <form @submit.prevent="saveNote(false)">
        <v-card>
          <v-card-title>
            <span class="headline">{{ noteFormTitle }}</span>
            <v-spacer />
            <v-btn
              class="ma-0 pa-0"
              color="primary"
              text
              small
              @click="$emit('manage-attendees')"
            >Manage attendees</v-btn>
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="noteFields.email"
              :items="attendees"
              item-value="email"
              item-text="displayName"
              label="Who"
              class="mt-5"
              outlined
            />
            <v-textarea
              v-model="noteFields.note"
              class="pb-0 mb-0"
              label="Note"
              rows="2"
              auto-grow
              outlined
              autofocus
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="noteFields.id"
              color="error"
              class="ml-2 mb-2"
              text
              @click="openDialogNoteConfirmDelete(noteFields)"
            >Delete</v-btn>
            <v-spacer />
            <v-btn text class="mb-2" @click="closeDialogNoteForm">Cancel</v-btn>
            <v-btn
              v-if="!noteFields.id"
              depressed
              class="mr-2 mb-2"
              @click="saveNote(true)"
            >Save + Next</v-btn>
            <v-btn color="success" class="mr-2 mb-2" @click="saveNote(false)">Save</v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <AppDialogConfirmDelete
      v-if="showDialogNoteConfirmDelete"
      message="You want to delete this note?"
      @confirm="deleteNote"
      @cancel="closeDialogNoteConfirmDelete"
    />

    <v-toolbar flat dense>
      <v-toolbar-title>
        <small>Notes</small>
      </v-toolbar-title>

      <v-spacer />

      <v-tooltip v-if="isEditable" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="addNote">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Note</span>
      </v-tooltip>
    </v-toolbar>

    <div v-if="hasNotes" class="v-data-table theme--light">
      <div class="v-data-table__wrapper">
        <draggable
          v-model="groupedNotes"
          v-bind="{
            ...dragOptions,
            disabled: disableDrag
          }"
          tag="table"
          @change="updateSortOrder"
        >
          <tbody v-for="(group, groupIndex) in groupedNotes" :key="groupIndex">
            <tr v-if="groupIndex > 0"><td colspan="3" style="height: 1px;"></td></tr>
            <tr
              v-for="(item, index) in group.items"
              :key="`${groupIndex}-${index}`"
              :class="{ 'draggable-list-item': !disableDrag }"
            >
              <td style="width: 200px;">
                <div class="text-no-wrap">
                  <strong v-if="index === 0">{{ item.email | nameByEmail(attendees) }}</strong>
                </div>
              </td>
              <td>
                <div
                  class="py-1 eos-tbl__content-grow"
                  :class="{ 'cursor-pointer': isEditable }"
                  v-html="$options.filters.nlToList(item.note)"
                  @click="editNote(item, $event)"
                >
                </div>
              </td>
              <td v-if="isEditable" style="width: 50px;">
                <div class="d-flex justify-end mr-1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small class="mr-2" @click="editNote(item)">mdi-pencil</v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small @click="openDialogNoteConfirmDelete(item)">mdi-delete</v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </draggable>
      </div>
    </div>

    <template v-if="isEditable">
      <div v-if="hasNotes" class="pa-0">
        <v-btn depressed small class="ma-3" @click="addNote">
          <v-icon x-small class="pr-2">mdi-plus</v-icon>Add Note
        </v-btn>
      </div>
      <v-btn v-else small outlined class="d-flex mt-3 mx-auto" @click="addNote">Add the first note</v-btn>
    </template>
    <div v-else-if="!hasNotes" class="text-center">
      <p>No note found</p>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'MeetingStepSegue',
  components: { draggable },
  props: {
    loggedInUser: {
      type: Object,
      required: true
    },
    level10Id: {
      type: String,
      required: true
    },
    initialSegueData: {
      type: Object,
      default: () => ({})
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    dragOptions: {
      type: Object,
      default: () => ({})
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      segueData: {
        sortOrder: [],
        notes: []
      },
      showDialogNoteForm: false,
      showDialogNoteConfirmDelete: false,
      noteFields: {
        email: '',
        note: ''
      },
      defaultNote: {
        email: this.loggedInUser.email,
        note: ''
      },
      noteToDelete: null,
      groupedNotes: []
    }
  },
  computed: {
    noteFormTitle () {
      return `${(this.noteFields.id ? 'Edit' : 'New')} Note`
    },
    disableDrag () {
      return this.segueData.sortOrder.length < 2 || !this.isEditable
    },
    hasNotes () {
      return this.segueData.notes.length > 0
    }
  },
  watch: {
    initialSegueData: {
      immediate: true,
      handler (data) {
        this.segueData = { ...this.segueData, ...data }
        this.segueData.notes.forEach(note => {
          note.id = note.id || this.$helpers.generateUniqueId()
        })

        this.segueData.notes.sort((a, b) => {
          // compare first name / email
          if (a.email !== b.email) {
            const nameA = this.$options.filters.nameByEmail(a.email, this.attendees)
            const nameB = this.$options.filters.nameByEmail(b.email, this.attendees)
            return ('' + nameA).localeCompare(nameB)
          }
          // compare note
          return ('' + a.note).localeCompare(b.note)
        })

        this.orderNotesByEmail()
      }
    }
  },
  methods: {
    orderNotesByEmail () {
      // group notes by email
      const groupedData = this.$options.filters.groupBy(this.segueData.notes, 'email')
      // add sort order attr if not present
      if (this.segueData.sortOrder.length === 0) this.$set(this.segueData, 'sortOrder', Object.keys(groupedData))
      // group notes by email
      this.groupedNotes = []
      this.segueData.sortOrder.forEach(email => this.groupedNotes.push({
        email,
        items: groupedData[email]
      }))
    },
    updateSortOrder () {
      const sortOrder = this.groupedNotes.map(g => g.email)
      this.$set(this.segueData, 'sortOrder', sortOrder)
      this.$emit('data-updated', this.segueData)
    },
    addNote () {
      this.noteFields = Object.assign({}, this.defaultNote)
      this.showDialogNoteForm = true
    },
    editNote (note, e) {
      if (!this.isEditable || (e && e.target.nodeName === 'A')) return
      this.noteFields = Object.assign({}, note)
      this.showDialogNoteForm = true
    },
    saveNote (keepForNext = false) {
      if (!this.noteFields.email || !this.noteFields.note) {
        return this.$emit('set-message', { type: 'error', message: 'Fill in the fields' })
      }

      if (this.noteFields.id) {
        const index = this.segueData.notes.findIndex(note => note.id === this.noteFields.id)
        if (index === -1) return this.$emit('set-message', { type: 'error', message: 'Note is deleted' })
        this.$set(this.segueData.notes, index, this.noteFields)
      } else {
        const newNoteItem = Object.assign({
          id: this.$helpers.generateUniqueId()
        }, this.noteFields)
        this.segueData.notes.push(newNoteItem)
      }

      // add to the bottom of the list if it's a new user
      if (!this.segueData.sortOrder.includes(this.noteFields.email)) {
        this.segueData.sortOrder.push(this.noteFields.email)
      }

      const msg = `Note ${(this.noteFields.id ? 'updated' : 'added')}`
      keepForNext ? this.noteFields.note = '' : this.closeDialogNoteForm()

      this.$emit('set-message', { type: 'success', message: msg })
      this.$emit('data-updated', this.segueData)
    },
    closeDialogNoteForm () {
      this.showDialogNoteForm = false
    },
    openDialogNoteConfirmDelete (item) {
      this.noteToDelete = item
      this.showDialogNoteConfirmDelete = true
    },
    deleteNote () {
      const index = this.segueData.notes.findIndex(note => note.id === this.noteToDelete.id)
      if (index > -1) {
        this.segueData.notes.splice(index, 1)
        // remove email from sortOrder if no notes available - deleting last item
        const sortOrder = this.groupedNotes.findIndex(note => note.email === this.noteToDelete.email)
        if (sortOrder > -1 && this.groupedNotes[sortOrder].items.length === 1) this.segueData.sortOrder.splice(sortOrder, 1)
        this.$emit('data-updated', this.segueData)
      }

      this.closeDialogNoteConfirmDelete()
      this.closeDialogNoteForm()
      this.$emit('set-message', { type: 'success', message: 'Note deleted' })
    },
    closeDialogNoteConfirmDelete () {
      this.showDialogNoteConfirmDelete = false
      this.noteToDelete = null
    }
  }
}
</script>
