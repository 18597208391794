<template>
  <AppDataTable
    sort-by="name"
    :items="sortedIssues"
  >
    <template v-slot:top>
      <v-dialog v-model="showDialogIssueForm" persistent max-width="400px">
        <form @submit.prevent="saveIssue(false)">
          <v-card>
            <v-card-title>
              <span class="headline">{{ issueFormTitle }}</span>
              <v-spacer />
              <v-btn
                text
                small
                color="primary"
                class="ma-0 pa-0"
                @click="$emit('manage-attendees')"
              >Manage attendees</v-btn>
            </v-card-title>

            <v-card-text>
              <v-select
                v-model="issueFields.email"
                :items="attendees"
                label="Who"
                item-value="email"
                item-text="displayName"
                class="pr-1 mt-5"
                outlined
              />

              <v-textarea
                v-model="issueFields.issue"
                label="Issue"
                rows="4"
                class="pb-0 mb-0"
                outlined
                autofocus
                hide-details
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="issueFields.id"
                text
                color="error"
                class="ml-2 mb-2"
                @click="openDialogIssueConfirmDelete(issueFields)"
              >Delete</v-btn>
              <v-spacer />
              <v-btn text class="mb-2" @click="closeDialogIssueForm">Cancel</v-btn>
              <v-btn
                v-if="!issueFields.id"
                depressed
                class="mr-2 mb-2"
                @click="saveIssue(true)"
              >Save + Next</v-btn>
              <v-btn color="success" class="mr-2 mb-2" @click="saveIssue(false)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>

      <AppDialogConfirmDelete
        v-if="showDialogIssueConfirmDelete"
        message="You want to delete this issue?"
        @confirm="deleteIssue"
        @cancel="closeDialogIssueDeleteConfirm"
      />

      <MeetingDialogTodoForm
        v-model="showDialogIssueTodoForm"
        :fields="issueTodoFields"
        :tw-sync-options="twSyncOptions"
        :attendees="attendees"
        :tw-projects="twProjects"
        :tw-tasklists="twTasklists"
        @manage-attendees="$emit('manage-attendees')"
        @save="updateIssueTodoFields($event, false)"
        @save-next="updateIssueTodoFields($event, true)"
        @delete="openDialogIssueTodoConfirmDelete"
        @set-message="$emit('set-message', $event)"
        @close="closeDialogIssueTodoForm"
      />

      <AppDialogConfirmDelete
        v-if="showDialogIssueTodoConfirmDelete"
        message="You want to delete this todo?"
        @confirm="deleteIssueTodo"
        @cancel="closeDialogIssueTodoConfirmDelete"
      />

      <v-toolbar flat dense>
        <v-toolbar-title>
          <small>Issues</small>
        </v-toolbar-title>

        <v-spacer />

        <v-tooltip v-if="isEditable" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="addIssue">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add Issue</span>
        </v-tooltip>
      </v-toolbar>
    </template>

    <template v-slot:body="{ items }">
      <tbody>
        <tr v-if="items.length === 0" class="eos-ids__no-data">
          <td class="text-center">
            <v-btn v-if="isEditable" small outlined class="mt-1" @click="addIssue">Add the first issue</v-btn>
          </td>
        </tr>

        <tr
          v-for="(item, index) in items"
          :key="item.id"
          class="eos-ids__issue"
          :class="{
            'eos-ids__issue--selected': item.status === 'Selected',
            'eos-ids__issue--discussed': item.status === 'Discussed',
            'eos-ids__issue--selected-or-discussed': item.status === 'Selected' || item.status==='Discussed'
          }"
        >
          <td
            class="eos-ids__attendee"
            :class="{ 'eos-ids__attendee--is-new': index === 0 || item.email !== items[index - 1].email }"
            style="width: 200px;"
            @click="editIssue(item)"
          >
            <span>{{ item.email | nameByEmail(attendees) }}</span>
          </td>

          <td class="ma-0 px-0 text-end eos-ids__state">
            <v-icon
              v-if="['Selected', 'Discussed'].includes(item.status) || !canVote"
              dark
              color="#333"
              @click="toggleStatusDiscussed(item)"
            >
              {{ item.status === 'Discussed' ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
            </v-icon>

            <template v-if="canVote && item.status === 'Not selected'">
              <v-tooltip v-if="item.votes.length > 0" bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-on="on"
                    :color="topRankedIssues.indexOf(item) > -1 ? 'green' : '#EEE'"
                    :text-color="topRankedIssues.indexOf(item) > -1 ? '#fff' : '#555'"
                    @click="selectIssue(item)"
                  >
                    <span v-if="top3RankedIssues.indexOf(item) > -1">
                      {{ top3RankedIssues.indexOf(item) + 1 }}
                    </span>
                    <span v-else>{{ topRankedIssues.indexOf(item) + 1 }}</span>
                  </v-chip>
                </template>
                <span>Click to select</span>
              </v-tooltip>
              <span v-else class="d-inline-block text-center exclaimation">!</span>
            </template>
          </td>

          <td>
            <div
              class="py-1 eos-tbl__content-grow"
              :class="{
                'font-weight-bold': item.status !== 'Not selected' && canVote,
                'cursor-pointer': isEditable
              }"
              @click="editIssue(item, $event)"
            >
              <span v-html="$options.filters.nlToBr(item.issue)"></span>
            </div>

            <ul v-if="item.todos && item.todos.length > 0" class="pb-3 eos-ids__issue__todo">
              <li v-for="todoObj in item.todos" :key="todoObj.id" class="pt-2">
                <v-chip small color="primary" class="eos-tbl__content-grow" dark @click.stop="editIssueTodo(item, todoObj)">
                  <v-avatar left>
                    <v-icon small>mdi-checkbox-blank-outline</v-icon>
                  </v-avatar>
                  {{ todoObj.email | nameByEmail(attendees) }}
                  <MeetingListOtherAssignees
                    v-if="todoObj.otherAssignees && todoObj.otherAssignees.length > 0"
                    :unique-id="todoObj.id"
                    :attendees="attendees"
                    :other-assignees="todoObj.otherAssignees"
                  />
                  <span class="mr-1">:</span>{{ todoObj.todo }}
                </v-chip>
              </li>
            </ul>
          </td>

          <td class="text-end" style="width: 300px;">
            <div class="d-flex align-center justify-end">
              <v-btn
                v-if="(isEditable && item.status !== 'Not selected') || !canVote"
                depressed
                small
                class="mr-2 hih"
                width="70px"
                @click="addIssueTodo(item)"
              >+ Todo</v-btn>

              <v-btn
                v-if="isEditable &&
                  [scribe, facilitator].includes(loggedInUser.email) &&
                  item.status === 'Not selected' &&
                  top3RankedIssues.indexOf(item) === -1 &&
                  canVote"
                depressed
                small
                class="hih mr-2"
                width="100px"
                style="font-size:9px"
                @click="selectIssue(item)"
              >Force Select</v-btn>

              <v-btn
                v-if="isEditable && item.status === 'Selected' && canVote"
                depressed
                small
                class="hih"
                width="70px"
                style="font-size:10px"
                @click="selectIssue(item)"
              >Unselect</v-btn>

              <v-tooltip v-if="canVote && item.status === 'Selected'" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    text
                    small
                    color="#333"
                    class="ml-2 mb-n1 align-self-center"
                  >
                    #{{ selectedIssues.indexOf(item) + 1 }}
                  </v-btn>
                </template>
                <span>Discussion priority</span>
              </v-tooltip>

              <v-chip
                v-if="item.status === 'Discussed'"
                small
                color="#333"
                text-color="white"
              >
                <v-avatar left>
                  <v-icon small>mdi-checkbox-marked-circle</v-icon>
                </v-avatar>Solved
              </v-chip>

              <div v-if="item.status === 'Not selected' && canVote" class="d-inline-flex">
                <v-btn
                  v-if="item.votes.length > 0"
                  text
                  x-small
                  class="mr-1 align-self-center"
                  @click="selectIssue(item)"
                >
                  {{ item.votes.length }}
                  <template v-if="item.votes.length === 1">vote</template>
                  <template v-else>votes</template>
                </v-btn>
                <v-btn
                  v-if="hasMyVote(item)"
                  depressed
                  dark
                  small
                  color="success"
                  width="70px"
                  @click="voteForIssue(item)"
                >My #{{ getMyVoteScoreByIssue(item) }}</v-btn>
                <v-btn
                  v-else
                  depressed
                  small
                  width="70px"
                  @click="voteForIssue(item)"
                >Vote</v-btn>
              </div>

              <MeetingStepIDSIssueComments
                :logged-in-user="loggedInUser"
                :attendees="attendees"
                :comments="item.comments"
                :is-editable="isEditable"
                @add-comment="addComment(item, $event)"
                @delete-comment="deleteComment(item, $event)"
                @set-message="$emit('set-message', $event)"
              />

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-2" @click="editIssue(item)">mdi-pencil</v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-2" @click="openDialogIssueConfirmDelete(item)">mdi-delete</v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </template>

    <v-container v-if="isEditable && sortedIssues.length > 0" slot="footer" pa-0 ma-0 fluid>
      <v-layout pa-0 ma-0 row>
        <v-flex pa-5 ma-0 sm6>
          <v-btn depressed small @click="addIssue">
            <v-icon x-small class="pr-2">mdi-plus</v-icon>Add Issue
          </v-btn>
        </v-flex>
        <v-flex v-if="votedUnselectedIssues.length && noOfSelectedIssues < 3 && canVote" pa-5 ma-0 sm6 class="text-end">
          <v-btn
            depressed
            small
            color="primary"
            @click="selectTop3VotedIssues"
          >
            <v-icon x-small class="mr-2">mdi-arrow-up</v-icon>Select top 3 issues
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </AppDataTable>
</template>

<script>
import MeetingDialogTodoForm from '@/components/meeting/dialogs/MeetingDialogTodoForm'
import MeetingStepIDSIssueComments from '@/components/meeting/steps/MeetingStepIDSIssueComments'
import MeetingListOtherAssignees from '@/components/meeting/MeetingListOtherAssignees'

export default {
  name: 'MeetingStepIDS',
  components: {
    MeetingDialogTodoForm,
    MeetingStepIDSIssueComments,
    MeetingListOtherAssignees
  },
  props: {
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    initialIdsData: {
      type: Object,
      default: () => ({})
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    scribe: {
      type: String,
      default: ''
    },
    facilitator: {
      type: String,
      default: ''
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    twProjects: {
      type: Array,
      default: () => ([])
    },
    twTasklists: {
      type: Array,
      default: () => ([])
    },
    canVote: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      IDSData: {
        issues: []
      },
      showDialogIssueForm: false,
      showDialogIssueConfirmDelete: false,
      selectedIssue: null,
      issueFields: {
        email: '',
        issue: '',
        status: 'Not selected',
        votes: [],
        todos: []
      },
      defaultIssue: {
        email: this.loggedInUser.email,
        issue: '',
        status: 'Not selected',
        votes: [],
        todos: []
      },
      showDialogIssueTodoForm: false,
      showDialogIssueTodoConfirmDelete: false,
      issueTodoFields: {
        email: '',
        projectId: null,
        tasklistId: null,
        taskId: null,
        todo: '',
        startDate: '',
        endDate: ''
      },
      defaultIssueTodo: {
        email: this.loggedInUser.email,
        projectId: null,
        tasklistId: null,
        taskId: null,
        todo: '',
        startDate: '',
        endDate: '',
        synced: false
      }
    }
  },
  computed: {
    issueIds () {
      return this.IDSData.issues.map(issue => issue.id)
    },
    sortedIssues () {
      return this.IDSData.issues.slice()
        .sort((a, b) => {
          // show discussed issue always on top
          // followed by selected and not-selected
          //
          if (a.status !== b.status && this.canVote) {
            const statusOptions = ['Discussed', 'Selected', 'Not selected']
            const aStatusIndex = statusOptions.indexOf(a.status)
            const bStatusIndex = statusOptions.indexOf(b.status)
            return (aStatusIndex < 0 || aStatusIndex < 0) ? 0 : aStatusIndex - bStatusIndex
          }

          // sort time difference
          if (
            a.status !== 'Not selected' &&
            b.status !== 'Not selected' &&
            typeof a.timeSelected === 'number' &&
            typeof a.timeSelected === 'number' &&
            this.canVote
          ) {
            return a.timeSelected - b.timeSelected
          }

          // sort by assignee
          if (a.email !== b.email) {
            const nameA = this.$options.filters.nameByEmail(a.email, this.attendees)
            const nameB = this.$options.filters.nameByEmail(b.email, this.attendees)
            return ('' + nameA).localeCompare(nameB)
          }
          return ('' + a.issue).localeCompare(b.issue)
        })
    },
    issueFormTitle () {
      return `${(this.issueFields.id ? 'Edit' : 'New')} Issue`
    },
    votedUnselectedIssues () {
      return this.IDSData.issues.filter(issue => issue.status === 'Not selected' && issue.votes.length > 0)
    },
    myAllVotedIssues () {
      return this.IDSData.issues.filter(issue => {
        return issue.status !== 'Discussed' &&
        issue.votes.findIndex(voteObj => voteObj.email === this.loggedInUser.email) > -1
      })
    },
    myVotedIssues () {
      return this.IDSData.issues.filter(issue => {
        if (issue.status !== 'Not selected') return false
        return issue.votes.findIndex(voteObj => voteObj.email === this.loggedInUser.email) > -1
      })
    },
    myVotedIssuesOrderedByVoteTime () {
      return this.myVotedIssues
        .map(issue => {
          const voteObj = issue.votes.find(voteObj => voteObj.email === this.loggedInUser.email)
          return { issue: issue, order: voteObj.order }
        })
        .sort((a, b) => a.order - b.order)
        .map(obj => obj.issue)
    },
    // Loop through all attendees - for each attendee, loop through votes and rank them according to vote order
    // assign a score according to the order the item was voted #1 = 10000, #2 = 9999 etc.
    // Next add up the score for all issues
    // Order by the scores
    // Top 3 issues are the ones to select
    //
    topRankedIssues () {
      const issueRankScores = {}
      this.attendees.forEach(attendee => {
        // get attenddee issues
        this.IDSData.issues
          .filter(issue => {
            if (issue.status !== 'Not selected') return false
            return issue.votes.findIndex(voteObj => voteObj.email === attendee.email) > -1
          })
          .map(issueObj => {
            const voteObj = issueObj.votes.find(voteObj => voteObj.email === attendee.email)
            return { issueObj: issueObj, order: voteObj.order }
          })
          .sort((a, b) => a.order - b.order)
          .map((orderIssue, index) => {
            const issueObj = orderIssue.issueObj
            // Build the rank scores
            issueRankScores[issueObj.id] = (10000 - index)
          })
      })

      // Now we have the scores, let do a final sort
      return this.votedUnselectedIssues
        .slice()
        .sort((a, b) => issueRankScores[b.id] - issueRankScores[a.id])
        .sort((a, b) => b.votes.length - a.votes.length)
    },
    top3RankedIssues () {
      const noOfIssuesToSelect = (3 - this.noOfSelectedIssues)
      return this.topRankedIssues.slice(0, noOfIssuesToSelect)
    },
    selectedIssues () {
      return this.sortedIssues.filter(issue => issue.status === 'Selected') || []
    },
    noOfSelectedIssues () {
      return this.selectedIssues.length
    }
  },
  watch: {
    initialIdsData: {
      immediate: true,
      handler (data) {
        this.IDSData = { ...this.IDSData, ...data }
        this.IDSData.issues.forEach((issue, index) => {
          issue.id = issue.id || this.$helpers.generateUniqueId()
          issue.votes = issue.votes || []
          issue.comments = issue.comments || []
          issue.todos = (issue.todos || []).map(todo => ({
            id: todo.id || this.$helpers.generateUniqueId(),
            ...todo
          }))
        })
      }
    },
    twSyncOptions: {
      immediate: true,
      handler (options) {
        Object.keys(this.defaultIssueTodo).forEach(key => {
          if (options[key]) this.defaultIssueTodo[key] = options[key]
        })
      }
    }
  },
  methods: {
    hasMyVote (issue) {
      return issue.votes.some(voteObj => voteObj.email === this.loggedInUser.email)
    },

    getMyVoteScoreByIssue (issue) {
      const myVotePos = this.myVotedIssuesOrderedByVoteTime.indexOf(issue)
      return myVotePos === -1 ? 0 : (myVotePos + 1)
    },

    addIssue () {
      this.issueFields = Object.assign({}, this.defaultIssue)
      this.showDialogIssueForm = true
    },

    editIssue (issueObj, e) {
      if (!this.isEditable || (e && e.target.nodeName === 'A')) return
      this.issueFields = Object.assign({}, issueObj)
      this.showDialogIssueForm = true
    },

    saveIssue (keepForNext = false) {
      // validate
      if (!this.issueFields.email || !this.issueFields.issue) {
        return this.$emit('set-message', { type: 'error', message: 'Fill in the fields' })
      }
      // update fields
      if (this.issueFields.id) {
        const index = this.issueIds.indexOf(this.issueFields.id)
        if (index === -1) return this.$emit('set-message', { type: 'error', message: 'Issue is deleted' })
        this.$set(this.IDSData.issues, index, this.issueFields)
      } else {
        const newIssueItem = Object.assign({
          id: this.$helpers.generateUniqueId()
        }, this.issueFields)
        this.IDSData.issues.push(newIssueItem)
      }

      this.$emit('set-message', { type: 'success', message: `Issue ${(this.issueFields.id ? 'updated' : 'added')}` })

      keepForNext ? this.issueFields.issue = '' : this.closeDialogIssueForm()
      this.emitUpdate()
    },

    closeDialogIssueForm () {
      this.showDialogIssueForm = false
    },

    openDialogIssueConfirmDelete (issue) {
      this.issueToDelete = issue
      this.showDialogIssueConfirmDelete = true
    },

    deleteIssue () {
      const index = this.issueIds.indexOf(this.issueToDelete.id)
      if (index === -1) return

      this.IDSData.issues.splice(index, 1)
      this.closeDialogIssueDeleteConfirm()
      this.closeDialogIssueForm()

      this.$emit('set-message', { type: 'success', message: 'Issue deleted' })
      this.emitUpdate()
    },

    closeDialogIssueDeleteConfirm () {
      this.showDialogIssueConfirmDelete = false
      this.issueToDelete = undefined
    },

    voteForIssue (issue) {
      if (!this.isEditable) return

      if (this.loggedInUser.isAnonymous) {
        return this.$emit('set-message', { type: 'error', message: 'You must be logged in to vote' })
      }

      const email = this.loggedInUser.email
      const pos = issue.votes.findIndex(voteObj => voteObj.email === email)
      // toggle vote
      if (pos === -1) {
        // has been voted for max no. of issues
        if (this.myAllVotedIssues.length >= 3) {
          return this.$emit('set-message', {
            type: 'error',
            message: 'You already have max. number of issues voted. Please unvote/discuss an issue first.'
          })
        }

        const voteObj = {
          order: new Date().getTime(),
          email: email
        }
        issue.votes.push(voteObj)
      } else {
        issue.votes.splice(pos, 1)
      }

      this.emitUpdate()
    },

    // Mark them selected
    selectTop3VotedIssues () {
      const time = new Date().getTime()
      this.top3RankedIssues.forEach((issue, index) => {
        this.$set(issue, 'status', 'Selected')
        this.$set(issue, 'timeSelected', (time + index))
      })
      this.emitUpdate()
    },

    selectIssue (item) {
      if (!this.isEditable) return
      if (this.noOfSelectedIssues === 3 && item.status === 'Not selected') {
        return this.showMaxSelectedWarning()
      }
      item.status = item.status === 'Selected' ? 'Not selected' : 'Selected'
      if (item.status === 'Selected') this.$set(item, 'timeSelected', new Date().getTime())
      this.emitUpdate()
    },

    toggleStatusDiscussed (item) {
      if (!this.isEditable) return
      if (this.canVote && this.noOfSelectedIssues === 3 && item.status === 'Discussed') {
        return this.showMaxSelectedWarning()
      }

      if (this.canVote) {
        item.status = item.status === 'Selected' ? 'Discussed' : 'Selected'
      } else {
        item.status = item.status === 'Discussed' ? 'Not selected' : 'Discussed'
      }
      this.emitUpdate()
    },

    showMaxSelectedWarning () {
      return this.$emit('set-message', {
        type: 'error',
        message: 'You already have max. number of issues selected. Please unselect an issue first.'
      })
    },

    addIssueTodo (issue) {
      this.selectedIssue = issue
      this.issueTodoFields = {}
      Object.keys(this.defaultIssueTodo).forEach(key => {
        this.issueTodoFields[key] = this.twSyncOptions[key] || this.defaultIssueTodo[key]
      })
      this.showDialogIssueTodoForm = true
    },

    editIssueTodo (issue, todo) {
      if (!this.isEditable) return
      this.selectedIssue = issue
      this.issueTodoFields = { ...this.defaultIssueTodo, ...todo, synced: false }
      this.showDialogIssueTodoForm = true
    },

    updateIssueTodoFields (issueTodoFields, keepForNext) {
      this.issueTodoFields = Object.assign({}, issueTodoFields)
      this.saveIssueTodo(keepForNext)
    },

    saveIssueTodo (keepForNext = false) {
      const issueIndex = this.issueIds.indexOf(this.selectedIssue.id)
      if (issueIndex === -1) return this.$emit('set-message', { type: 'error', message: 'Issue is deleted' })

      if (this.issueTodoFields.id) {
        const todoIndex = this.IDSData.issues[issueIndex].todos.findIndex(todo => todo.id === this.issueTodoFields.id)
        if (todoIndex === -1) return this.$emit('set-message', { type: 'error', message: 'Todo is deleted' })
        this.$set(this.IDSData.issues[issueIndex].todos, todoIndex, this.issueTodoFields)
      } else {
        const newTodoItem = Object.assign({
          id: this.$helpers.generateUniqueId()
        }, this.issueTodoFields)
        this.IDSData.issues[issueIndex].todos.push(newTodoItem)
      }

      this.$emit('set-message', { type: 'success', message: `Todo ${(this.issueTodoFields.id ? 'updated' : 'added')}` })

      keepForNext ? this.issueTodoFields.todo = '' : this.closeDialogIssueTodoForm()
      this.emitUpdate()
    },

    closeDialogIssueTodoForm () {
      this.showDialogIssueTodoForm = false
      this.selectedIssue = null
    },

    openDialogIssueTodoConfirmDelete () {
      const issueIndex = this.issueIds.indexOf(this.selectedIssue.id)
      if (issueIndex === -1) {
        return this.$emit('set-message', { type: 'error', message: 'Issue is deleted' })
      }
      this.showDialogIssueTodoConfirmDelete = true
    },

    deleteIssueTodo () {
      const issueIndex = this.issueIds.indexOf(this.selectedIssue.id)
      if (issueIndex === -1) return this.$emit('set-message', { type: 'error', message: 'Issue is deleted' })

      const todoIndex = this.selectedIssue.todos.findIndex(todo => todo.id === this.issueTodoFields.id)
      if (todoIndex === -1) return this.$emit('set-message', { type: 'error', message: 'Todo is deleted' })

      const todo = this.selectedIssue.todos[todoIndex]
      if (todo.taskId) {
        this.$emit('delete-todo', todo.taskId)
      }

      this.selectedIssue.todos.splice(todoIndex, 1)
      this.closeDialogIssueTodoConfirmDelete()
      this.closeDialogIssueTodoForm()

      this.$emit('set-message', { type: 'success', message: 'Todo deleted' })
      this.emitUpdate()
    },

    closeDialogIssueTodoConfirmDelete () {
      this.showDialogIssueTodoConfirmDelete = false
    },

    emitUpdate () {
      this.$emit('data-updated', this.IDSData)
    },

    addComment (issue, comment) {
      issue.comments.push(comment)
      this.emitUpdate()
    },

    deleteComment (issue, index) {
      issue.comments.splice(index, 1)
      this.emitUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.hih {
  visibility: hidden;
}

tr.eos-ids__no-data {
  &:hover {
    td {
      background-color: #fff;
    }
  }
}

tr.eos-ids__issue--selected {
  background-color: #f5f5f5;
}

tr.eos-ids__issue--discussed {
  background-color: #ddd;
  &:hover {
    background-color: #ddd;
  }
}

tr.eos-ids__issue--selected-or-discussed {
  td.eos-ids__attendee {
    span {
      color: #000;
    }
  }
}

tr.eos-ids__issue {
  &:hover {
    .hih {
      visibility: visible;
    }
  }
}

td.eos-ids__attendee {
  min-width: 150px;
  span {
    color: #777;
    visibility: hidden;
  }
}

td.eos-ids__attendee--is-new {
  span {
    visibility: visible;
  }
}

.eos-ids__state {
  width: 70px;
}

.eos-ids__issue__todo {
  list-style: none;
}

.exclaimation {
  width: 34px;
  height: 34px;
  line-height: 34px;
  background: #F7F7F7;
  color: #555;
  border-radius: 50%;
}
</style>
