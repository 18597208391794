<template>
  <div class="mt-3 pa-5">
    <p class="mb-2">Message to Share</p>

    <div v-if="text" class="d-flex">
      <div
        class="py-1"
        :class="{ 'cursor-pointer': isEditable }"
        v-html="$options.filters.nlToList(text)"
        @click="openEditDialog"
      >
      </div>
      <v-tooltip v-if="isEditable" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon class="d-flex ml-auto" @click="openEditDialog">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Message</span>
      </v-tooltip>
    </div>
    <v-btn v-else small outlined class="d-flex mx-auto" @click="openEditDialog">Add message</v-btn>

    <v-dialog
      v-model="showDialog"
      persistent
      width="400px"
    >
      <v-card>
        <v-card-title class="px-4">Edit Message to Share</v-card-title>
        <v-card-text class="mt-5 px-4">
          <v-textarea
            v-model="message"
            label="Message"
            rows="5"
            auto-grow
            outlined
            autofocus
            hide-details
          />
        </v-card-text>
        <v-card-actions class="px-4 pb-3">
          <v-spacer />
          <v-btn text @click="closeEditDialog">Cancel</v-btn>
          <v-btn color="success" :loading="isUpdatingNotes" @click="$emit('update', message)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'MeetingStepConcludeNotes',
  props: {
    text: {
      type: String,
      default: ''
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isUpdatingNotes: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showDialog: false,
      message: ''
    }
  },
  watch: {
    text: {
      immediate: true,
      handler (txt) {
        this.message = txt || ''
      }
    }
  },
  methods: {
    openEditDialog () {
      if (!this.isEditable) return
      this.showDialog = true
    },
    closeEditDialog () {
      this.showDialog = false
    }
  }
}
</script>
