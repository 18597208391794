<template>
  <tr>
    <td colspan="7" class="pa-0">
      <div class="d-flex align-center grey lighten-4 px-3 py-3">
        <div v-if="description" class="d-flex flex-column flex-grow-1 mr-2">
          <v-expand-transition>
            <div
              v-show="isDescriptionExpanded"
              class="mb-3 cursor-pointer"
              v-html="$options.filters.nlToList(description)"
              @click="$emit('edit-desc', $event)"
            >
            </div>
          </v-expand-transition>

          <div v-if="!persistentDescription">
            <v-btn
              depressed
              small
              class="text-capitalize text--secondary"
              @click="isDescriptionExpanded = !isDescriptionExpanded"
            >
              <v-icon color="grey">{{ descBtnIcon }}</v-icon>
              <span>{{ descBtnText }}</span>
            </v-btn>
          </div>
        </div>
        <div v-else class="px-2 mt-1">No description found</div>
      </div>
      <slot name="deliverables"></slot>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'KeyPriorityListItemDetails',
  props: {
    description: {
      type: String,
      default: ''
    },
    persistentDescription: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isDescriptionExpanded: true
    }
  },
  computed: {
    descBtnIcon () {
      return `mdi-chevron-${this.isDescriptionExpanded ? 'up' : 'right'}`
    },
    descBtnText () {
      return `${this.isDescriptionExpanded ? 'Collapse' : 'View'} Description`
    }
  }
}
</script>
