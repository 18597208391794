var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppDataTable',{attrs:{"sort-by":"name","items":_vm.summaryTodos},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('MeetingDialogTodoForm',{attrs:{"fields":_vm.todoFields,"tw-sync-options":_vm.twSyncOptions,"attendees":_vm.attendees,"tw-projects":_vm.twProjects,"tw-tasklists":_vm.twTasklists},on:{"manage-attendees":function($event){return _vm.$emit('manage-attendees')},"save":function($event){return _vm.updateTodoFields($event, false)},"save-next":function($event){return _vm.updateTodoFields($event, true)},"delete":_vm.openDialogTodoConfirmDelete,"set-message":function($event){return _vm.$emit('set-message', $event)},"close":_vm.closeDialogTodoForm},model:{value:(_vm.showDialogTodoForm),callback:function ($$v) {_vm.showDialogTodoForm=$$v},expression:"showDialogTodoForm"}}),(_vm.showDialogTodoConfirmDelete)?_c('AppDialogConfirmDelete',{attrs:{"message":"You want to delete this todo?"},on:{"confirm":_vm.deleteTodo,"cancel":_vm.closeDialogTodoConfirmDelete}}):_vm._e(),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_c('small',[_vm._v("Todos Summary")])]),_c('v-spacer'),(_vm.isEditable)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.addTodo}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,712661033)},[_c('span',[_vm._v("Add Todo")])]):_vm._e()],1)]},proxy:true},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[(items.length === 0)?[_c('tr',{staticClass:"eos-meeting__todo-summary--empty-state"},[_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"d-flex mx-auto",attrs:{"small":"","outlined":""},on:{"click":_vm.addTodo}},[_vm._v("Add to-do")])],1)])]:_vm._e(),_vm._l((items),function(item,index){return _c('tr',{key:("eos-conclude-todos-" + index)},[_c('td',{staticClass:"pr-0",staticStyle:{"width":"20px"}},[_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])],1),_c('td',{staticClass:"pl-2 eos-meeting__todo-summary__email",class:{ 'cursor-pointer': _vm.isEditable },on:{"click":function($event){return _vm.editTodo(item.todoObj)}}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("nameByEmail")(item.todoObj.email,_vm.attendees)))]),(item.todoObj.otherAssignees && item.todoObj.otherAssignees.length > 0)?_c('MeetingListOtherAssignees',{attrs:{"unique-id":item.todoObj.id,"attendees":_vm.attendees,"other-assignees":item.todoObj.otherAssignees}}):_vm._e()],1),_c('td',{staticClass:"eos-meeting__todo-summary__type",class:{ 'cursor-pointer': _vm.isEditable }},[(item.type)?_c('v-chip',{attrs:{"small":"","color":"#F4F4F4"}},[_vm._v(_vm._s(item.type))]):_vm._e()],1),_c('td',{staticClass:"pl-2",class:{ 'cursor-pointer': _vm.isEditable },on:{"click":function($event){return _vm.editTodo(item.todoObj)}}},[_vm._v(_vm._s(item.todoObj.todo))]),(_vm.isEditable)?_c('td',{staticStyle:{"width":"50px"}},[_c('div',{staticClass:"d-flex align-center justify-end mr-1"},[(item.todoObj.taskId && _vm.twSyncOptions.domain)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2 cursor-pointer",attrs:{"size":"14px"},domProps:{"textContent":_vm._s('$vuetify.icons.tw-teamwork')},on:{"click":function($event){return _vm.$emit('go-to-tw-task', item.todoObj.taskId)}}},on))]}}],null,true)},[_c('span',[_vm._v("Open Teamwork Task")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editTodo(item.todoObj)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.confirmAndDeleteTodo(item.todoObj)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]):_vm._e()])})],2)]}}])}),(_vm.summaryTodos.length > 0)?_c('v-btn',{staticClass:"ma-3",attrs:{"depressed":"","small":""},on:{"click":_vm.addTodo}},[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":""}},[_vm._v("mdi-plus")]),_vm._v("Add To-do ")],1):_vm._e(),(!_vm.twSyncOptions.accessToken || !_vm.twSyncOptions.projectId || !_vm.twSyncOptions.tasklistId)?_c('p',{staticClass:"text-center mt-3 eos-meeting__todo-summary__tw-branding"},[_vm._v(" You know you can sync your todos with the project management software "),_c('AppTeamworkBranding'),_vm._v("? "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://teamwork.grsm.io/instantagencytools","target":"_blank"}},[_vm._v("Learn more")]),_vm._v(" or click here to "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$emit('show-sync-options')}}},[_vm._v("sync now")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }