<template>
  <AppDataTable
    sort-by="name"
    :items="sortedMeetingRatings"
  >
    <template v-slot:top>
      <v-dialog v-model="showDialogMeetingRatingEdit" persistent max-width="400px">
        <form @submit.stop="saveMeetingRating(false)">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Rating</span>
              <v-spacer />
              <v-btn
                text
                small
                color="primary"
                class="ma-0 pa-0"
                @click="$emit('manage-attendees')"
              >Manage attendees</v-btn>
            </v-card-title>
            <v-card-text>
              <v-select
                v-model.trim="meetingRatingFields.email"
                :items="attendees"
                item-value="email"
                item-text="displayName"
                label="Who"
                class="mt-5"
                outlined
              />
              <v-slider
                v-model="meetingRatingFields.rating"
                class="align-center"
                :max="10"
                :min="0"
                outlined
              >
                <template v-slot:prepend>
                  <v-text-field
                    v-model="meetingRatingFields.rating"
                    label="Rating out of 10"
                    class="mt-0 pt-0 eos-meeting__conclude-ratings__input"
                    type="number"
                    outlined
                    autofocus
                  />
                </template>
              </v-slider>
              <v-textarea
                v-model="meetingRatingFields.comment"
                label="Comment"
                rows="4"
                class="pb-0 mb-0"
                outlined
                hide-details
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text color="error" class="mb-2" @click="closeDialogMeetingRatingEdit">Cancel</v-btn>
              <v-btn color="success" class="mr-2 mb-2" @click="saveMeetingRating(false)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
      <v-toolbar flat dense>
        <v-toolbar-title>
          <small>Ratings</small>
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </template>

    <template v-slot:body="{ items }">
      <tbody>
        <tr v-for="(item, index) in items" :key="`eos-meeting-rating-${index}`">
          <td class="pr-0 eos-meeting__conclude-ratings__avatar">
            <AppAvatar
              size="24px"
              :src="item.user.photoURL"
              :name="item.user.displayName"
            />
          </td>
          <td
            class="font-weight-bold eos-meeting__conclude-ratings__attendee"
            @click="editMeetingRating(item)"
          >{{ item.user.displayName }}</td>
          <td class="pa-0" :class="{ 'cursor-pointer': canVoteFor(item.user.email) }" @click="editMeetingRating(item)">
            <v-btn
              v-if="isEditable"
              icon
              class="mr-2"
              :class="{ 'eos-meeting__btn-vote--invisible': !canVoteFor(item.user.email) }"
              @click.stop="downVoteMeeting(item)"
            >
              <v-icon small color="grey lighten-1">mdi-arrow-down</v-icon>
            </v-btn>

            <span class="d-inline-block">
              <v-chip
                dark
                class="red"
                :class="{'green': item.rating >= 7 }"
              >{{ item.rating }}/10</v-chip>
            </span>

            <v-btn
              v-if="isEditable"
              icon
              class="ml-2"
              :class="{ 'eos-meeting__btn-vote--invisible': !canVoteFor(item.user.email) }"
              @click.stop="upVoteMeeting(item)"
            >
              <v-icon small color="grey lighten-1">mdi-arrow-up</v-icon>
            </v-btn>

            <v-chip v-if="item.comment" class="ml-2">{{ item.comment }}</v-chip>
          </td>
          <td v-if="isEditable" class="text-end">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  small
                  class="mr-2"
                  :class="{ 'eos-meeting__btn-vote--invisible': !canVoteFor(item.user.email) }"
                  @click.prevent="editMeetingRating(item)"
                >mdi-pencil</v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>

    <template v-slot:footer>
      <div v-if="sortedMeetingRatings.length > 0" class="pa-5">
        <v-chip class="mr-2 red" dark :class="{ 'green': avgRating >= 7}">
          Average rating: {{ avgRating }}/10
          <v-icon v-if="avgRating >= 7" right>mdi-trophy-award</v-icon>
        </v-chip>
      </div>
    </template>
  </AppDataTable>
</template>

<script>
export default {
  name: 'MeetingStepConcludeRatings',
  props: {
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    initialConcludeData: {
      type: Object,
      default: () => ({})
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    avgRating: {
      type: [String, Number],
      default: 10
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    scribe: {
      type: String,
      default: ''
    },
    facilitator: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      concludeData: {
        meetingRatings: []
      },
      showDialogMeetingRatingEdit: false,
      meetingRatingFields: {
        email: '',
        rating: 8,
        comment: ''
      },
      defaultMeetingRating: {
        email: this.loggedInUser.email,
        rating: 8,
        comment: ''
      }
    }
  },
  computed: {
    sortedMeetingRatings () {
      return this.attendees
        .filter(attendee => typeof attendee.isPresent !== 'boolean' || attendee.isPresent)
        .map(attendee => {
          const user = Object.assign({}, attendee) // temp to save some calculations
          user.displayName = this.$options.filters.nameByEmail(user.email, this.attendees)

          const meetingRating = this.concludeData.meetingRatings.find(meetingRating => meetingRating.email === attendee.email) ||
            { ...this.defaultMeetingRating, email: attendee.email }
          return { ...meetingRating, user }
        })
        .sort((a, b) => {
          return ('' + a.user.displayName).localeCompare(b.user.displayName)
        })
    }
  },
  watch: {
    initialConcludeData: {
      immediate: true,
      handler (data) {
        this.concludeData = { ...this.concludeData, ...data }
        this.concludeData.meetingRatings.forEach(meetingRating => {
          meetingRating.comment = meetingRating.comment || ''
        })
      }
    }
  },
  methods: {
    canVoteFor (email) {
      return email === this.loggedInUser.email || this.loggedInUser.email === this.scribe || this.loggedInUser.email === this.facilitator
    },
    editMeetingRating (item) {
      if (!this.isEditable || !this.canVoteFor(item.user.email)) return
      this.meetingRatingFields = Object.assign({}, item)
      this.showDialogMeetingRatingEdit = true
    },
    saveMeetingRating () {
      if (!this.meetingRatingFields.email ||
        this.meetingRatingFields.rating < 0 ||
        this.meetingRatingFields.rating > 10
      ) {
        return this.$emit('set-message', { type: 'error', message: 'Fill in the fields' })
      }
      this.addOrUpdateMeetingRating(this.meetingRatingFields)
      this.closeDialogMeetingRatingEdit()
    },
    addOrUpdateMeetingRating (item) {
      delete item.user // remove temp user prop
      const index = this.concludeData.meetingRatings.findIndex(mr => mr.email === item.email)

      index > -1 ? this.$set(this.concludeData.meetingRatings, index, item) : this.concludeData.meetingRatings.push(item)

      this.$emit('set-message', { type: 'success', message: 'Rating updated' })
      this.$emit('data-updated', this.concludeData)
    },
    closeDialogMeetingRatingEdit () {
      this.showDialogMeetingRatingEdit = false
    },
    upVoteMeeting (item) {
      const rating = Math.min(item.rating + 1, 10)
      if (rating > 10) return
      this.addOrUpdateMeetingRating({ ...item, rating })
    },
    downVoteMeeting (item) {
      const rating = Math.max(item.rating - 1, 0)
      if (rating < 0) return
      this.addOrUpdateMeetingRating({ ...item, rating })
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-meeting__conclude-ratings__input {
  width: 150px;
}

.eos-meeting__conclude-ratings__avatar {
  width: 20px;
}

.eos-meeting__conclude-ratings__attendee {
  width: 200px;
}

.eos-meeting__btn-vote--invisible {
  visibility: hidden;
}
</style>
