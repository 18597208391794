<template>
  <tr>
    <td class="draggable-list-item" style="width: 200px;">
      <div class="d-flex align-center">
        <v-icon v-if="isExpandable" class="ml-n1 mr-2 cursor-pointer" @click="toggle()">
          {{ expandIcon }}
        </v-icon>
        <div class="text-no-wrap">
          <strong v-if="withTitle">{{ item.email | nameByEmail(users) }}</strong>
        </div>
      </div>
    </td>
    <td v-if="withType" style="width: 80px;">
      <v-chip
        v-if="item.type"
        small
        :class="{ 'cursor-pointer': isEditable }"
        @click="$emit('edit', { item, e: $event })"
      >{{ item.type }}</v-chip>
    </td>
    <td v-if="withDeliverables" class="px-0" style="width: 35px;">
      <v-tooltip v-if="hasDeliverables" bottom>
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" :color="delCountChipColor" dark small @click="toggle(true)">
            {{ deliverablesCount }}
          </v-chip>
        </template>
        <span>
          <p class="mb-1">Total Deliverables: {{ deliverablesCount }}</p>
          <div class="d-flex flex-column"
            v-for="key in Object.keys(deliverablesByStatus)"
            :key="`${item.id}-del-by-status-${key}`"
          >
            {{ key }}: {{ deliverablesByStatus[key] }}
          </div>
        </span>
      </v-tooltip>
    </td>
    <td>
      <div
        class="py-1"
        :class="{ 'cursor-pointer': isEditable }"
        @click="$emit('edit', { item, e: $event })"
      >
        <span v-html="$options.filters.nlToBr(item.rock)"></span>
      </div>
    </td>
    <td style="width: 100px;">
      <div class="d-flex align-center justify-end">
        <AppDatePicker
          title="Start Date"
          default-text="Start Date"
          date-style="short"
          spacing-class="mx-0 px-0"
          text-class="text-caption"
          editable
          :date="item.startDate || ''"
          @change="updateDate($event, 'startDate')"
        />
        <span class="px-1">-</span>
        <AppDatePicker
          title="End Date"
          default-text="End Date"
          date-style="short"
          spacing-class="mx-0 px-0"
          text-class="text-caption"
          editable
          :date="item.endDate || ''"
          @change="updateDate($event, 'endDate')"
        />
      </div>
    </td>
    <td style="width: 150px;">
      <v-chip :color="statusColor" dark @click="$emit('edit', { item })">
        <v-avatar v-if="item.status === 'Complete'" left>
          <v-icon>mdi-checkbox-marked-circle</v-icon>
        </v-avatar>
        {{ item.status }}
        <template v-if="item.status !== 'Complete' && item.progress > 0">
          {{ item.progress }}%
        </template>
      </v-chip>
    </td>
    <td v-if="isEditable" style="width: 100px;">
      <div class="d-flex justify-end mr-1">
        <slot name="actions"></slot>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small class="mr-2" @click="$emit('edit', { item })">mdi-pencil</v-icon>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small @click="$emit('delete', item)">mdi-delete</v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </div>
    </td>
  </tr>
</template>

<script>
import AppDatePicker from '@/components/shared/datepickers/AppDatePicker'
import KEY_PRIORITIES_STATUSES from '@/enums/keyPriorityStatuses'

export default {
  name: 'KeyPriorityListItem',
  components: { AppDatePicker },
  props: {
    users: {
      type: Array,
      default: () => ([])
    },
    item: {
      type: Object,
      default: () => ({})
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    withTitle: {
      type: Boolean,
      default: false
    },
    withDeliverables: {
      type: Boolean,
      default: false
    },
    withType: {
      type: Boolean,
      default: false
    },
    isExpandable: {
      type: Boolean,
      default: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    expandIcon () {
      return `mdi-chevron-${this.isExpanded ? 'down' : 'right'}`
    },
    statusColor () {
      const status = this.item.status
      if (status === 'Off track') return 'warning'
      if (status === 'Incomplete') return 'error'
      return status === 'Complete' ? 'secondary' : 'green'
    },
    deliverables () {
      return this.item.deliverables || []
    },
    deliverablesCount () {
      return this.deliverables.length
    },
    hasDeliverables () {
      return this.deliverablesCount > 0
    },
    completedDeliverables () {
      return this.deliverables.filter(del => del.status === KEY_PRIORITIES_STATUSES.COMPLETED)
    },
    completedDeliverablesCount () {
      return this.completedDeliverables.length
    },
    onTrackDeliverables () {
      return this.deliverables.filter(del => del.status === KEY_PRIORITIES_STATUSES.ON_TRACK)
    },
    onTrackDeliverablesCount () {
      return this.onTrackDeliverables.length
    },
    offTrackDeliverables () {
      return this.deliverables.filter(del => del.status === KEY_PRIORITIES_STATUSES.OFF_TRACK)
    },
    offTrackDeliverablesCount () {
      return this.offTrackDeliverables.length
    },
    incompletedDeliverables () {
      return this.deliverables.filter(del => del.status === KEY_PRIORITIES_STATUSES.INCOMPLETE)
    },
    incompletedDeliverablesCount () {
      return this.incompletedDeliverables.length
    },
    deliverablesByStatus () {
      const dels = {}
      dels[KEY_PRIORITIES_STATUSES.COMPLETED] = this.completedDeliverablesCount
      dels[KEY_PRIORITIES_STATUSES.ON_TRACK] = this.onTrackDeliverablesCount
      dels[KEY_PRIORITIES_STATUSES.OFF_TRACK] = this.offTrackDeliverablesCount
      dels[KEY_PRIORITIES_STATUSES.INCOMPLETE] = this.incompletedDeliverablesCount
      return dels
    },
    delCountChipColor () {
      if (this.incompletedDeliverablesCount > 0) return 'error'
      if (this.offTrackDeliverablesCount > 0) return 'warning'
      if (this.onTrackDeliverablesCount > 0) return 'success'

      return 'grey'
    }
  },
  methods: {
    toggle (onlyIfNotExpanded = false) {
      if (onlyIfNotExpanded && this.isExpanded) return
      this.$emit('toggle', this.item.id)
    },
    async updateDate (date, field) {
      this.$set(this.item, field, date)

      // check another field
      const anotherField = field === 'startDate' ? 'endDate' : 'startDate'

      // assign selected field to another if it's un-assigned
      if (!this.item[anotherField]) {
        this.item[anotherField] = date
      } else { // end date should be >= start date
        const diffTime = new Date(this.item.endDate) - new Date(this.item.startDate)
        if (diffTime < 0) this.$set(this.item, anotherField, date)
      }

      await this.$nextTick()
      this.$emit('update-date', this.item)
    }
  }
}
</script>
