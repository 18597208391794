<template>
  <div>
    <KeyPriorityDialogForm
      v-model="showDialogRockForm"
      :title="rockFields.parentRockId ? 'Deliverable' : 'Key Priority'"
      :users="attendees"
      :fields="rockFields"
      manage-users-btn-text="Manage Attendees"
      @manage-users="$emit('manage-attendees')"
      @save="updateRockFields($event, false)"
      @save-next="updateRockFields($event, true)"
      @delete="openDialogRockConfirmDelete"
      @set-message="$emit('set-message', $event)"
      @close="closeDialogRockForm"
    />

    <AppDialogConfirmDelete
      v-if="showDialogRockConfirmDelete"
      :message="`You want to delete this ${rockToDelete.parentRockId ? 'deliverable' : 'key priority'}?`"
      @confirm="deleteRock"
      @cancel="closeDialogRockConfirmDelete"
    />

    <v-toolbar flat dense>
      <v-toolbar-title>
        <small>Key Priorities</small>
      </v-toolbar-title>

      <v-spacer />

      <KeyPrioritiesFilterStatus
        v-model="selectedStatusFilters"
        :statuses="rockStatuses"
      />

      <v-tooltip v-if="isEditable" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="addRock()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Add Key Priority</span>
      </v-tooltip>
    </v-toolbar>

    <div v-if="hasRocks" class="v-data-table theme--light">
      <div class="v-data-table__wrapper">
        <draggable
          v-model="groupedRocks"
          v-bind="{
            ...dragOptions,
            disabled: disableDrag
          }"
          tag="table"
          @change="updateSortOrder"
        >
          <tbody v-for="(group, groupIndex) in groupedRocks" :key="groupIndex">
            <tr v-if="groupIndex > 0"><td colspan="5" style="height: 1px;"></td></tr>
            <template v-for="(item, index) in filteredRocks(group.items)">
              <KeyPriorityListItem
                :key="`${groupIndex}-${index}`"
                :class="{ 'draggable-list-item': !disableDrag }"
                :users="attendees"
                :item="item"
                :is-expanded="expandedItemIds.includes(item.id)"
                :is-editable="isEditable"
                :with-title="index === 0"
                with-type
                with-deliverables
                @toggle="toggleDetails"
                @edit="editRock"
                @update-date="updateDate"
                @delete="openDialogRockConfirmDelete"
              >
                <div slot="actions" class="d-flex align-center">
                  <v-tooltip v-if="userRocktools.length > 0" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on" class="mr-2" @click="linkWithRocktool(item.id)">mdi-bullseye-arrow</v-icon>
                    </template>
                    <span>Link with Key Priorities</span>
                  </v-tooltip>
                  <v-tooltip v-if="userVTOs.length > 0" bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on" class="mr-1" @click="linkWithVTO(item.id)">mdi-binoculars</v-icon>
                    </template>
                    <span>Link with Vision & Plan</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" class="mr-2" @click="addRock(item)">mdi-plus</v-icon>
                    </template>
                    <span>Add Deliverable</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small class="mr-2" @click="logIssue(item)">mdi-alert-octagon-outline</v-icon>
                    </template>
                    <span>Log an issue</span>
                  </v-tooltip>
                </div>
              </KeyPriorityListItem>
              <v-expand-transition :key="`keypriority-details-${index}`">
                <KeyPriorityListItemDetails
                  v-show="expandedItemIds.includes(item.id)"
                  :description="item.description"
                  @edit-desc="e => editRock({ item, e })"
                >
                  <div v-if="item.deliverables.length > 0" class="pl-3 pb-3 grey lighten-4" slot="deliverables">
                    <div class="v-data-table theme--light">
                      <div class="v-data-table__wrapper grey lighten-4">
                        <draggable
                          v-model="item.deliverables"
                          v-bind="{
                            ghostClass: 'ghost',
                            disabled: item.deliverables.length < 2
                          }"
                          tag="table"
                          style="width: 100%;"
                          @end="emitUpdate"
                        >
                          <tbody
                            v-for="(dl, deliverableIndex) in item.deliverables"
                            :key="`key-priority-dl-${deliverableIndex}`"
                            @edit-desc="e => editRock({ item, e })"
                          >
                            <KeyPriorityListItem
                              :users="attendees"
                              :item="dl"
                              :is-expanded="expandedItemIds.includes(dl.id)"
                              with-title
                              class="white"
                              @toggle="toggleDetails"
                              @edit="editRock"
                              @update-date="updateDate"
                              @delete="openDialogRockConfirmDelete"
                            />
                            <KeyPriorityListItemDetails
                              v-show="expandedItemIds.includes(dl.id)"
                              :description="dl.description"
                              persistent-description
                              class="white"
                              @edit-desc="e => editRock({ dl, e })"
                            />
                          </tbody>
                        </draggable>
                      </div>
                    </div>
                  </div>
                </KeyPriorityListItemDetails>
              </v-expand-transition>
            </template>
          </tbody>
        </draggable>
      </div>
    </div>

    <template v-if="isEditable">
      <div v-if="hasRocks" class="pa-0">
        <v-btn depressed small class="ma-3" @click="addRock()">
          <v-icon x-small class="pr-2">mdi-plus</v-icon>Add Key Priority
        </v-btn>
      </div>
      <v-btn v-else small outlined class="d-flex mt-3 mx-auto" @click="addRock()">Add Key Priority</v-btn>
    </template>
    <div v-else-if="!hasRocks" class="text-center">
      <p>No key priority found</p>
    </div>

    <KeyPriorityDialogLinks
      v-if="showLinksDialog"
      :fetching-items="isFetchingLinkedItems"
      :show-loader="isUpdatingLinks"
      :title="linkDialogTitle"
      :data="linkDialogData"
      :selected-ids="selectedIds"
      @update-relation="updateKeyPriorityRelations"
      @close="closeLinksDialog"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'
import DataManager from '@/helpers/dataManager'
import KeyPriorityDialogForm from '@/components/keyPriorities/dialogs/KeyPriorityDialogForm'
import KeyPriorityListItem from '@/components/keyPriorities/KeyPriorityListItem'
import KeyPriorityListItemDetails from '@/components/keyPriorities/KeyPriorityListItemDetails'
import KeyPriorityDialogLinks from '@/components/keyPriorities/dialogs/KeyPriorityDialogLinks'
import KeyPrioritiesFilterStatus from '@/components/keyPriorities/KeyPrioritiesFilterStatus'
import KEY_PRIORITIES_STATUSES from '@/enums/keyPriorityStatuses'

const Queue = require('queue')
const queue = new Queue({ autostart: true, concurrency: 1 })
const ADD_KP = 'add'
const REMOVE_KP = 'remove'

export default {
  name: 'MeetingStepRocks',
  components: {
    draggable,
    KeyPriorityDialogForm,
    KeyPriorityListItem,
    KeyPriorityListItemDetails,
    KeyPriorityDialogLinks,
    KeyPrioritiesFilterStatus
  },
  props: {
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    initialRocksData: {
      type: Object,
      default: () => ({})
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    dragOptions: {
      type: Object,
      default: () => ({})
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    level10Id: {
      type: String,
      default: ''
    }
  },
  data () {
    const dataManager = new DataManager()

    return {
      dataManager: dataManager,
      rocksData: {
        sortOrder: [],
        rocks: []
      },
      showDialogRockForm: false,
      showDialogRockConfirmDelete: false,
      rockFields: {
        email: '',
        rock: '',
        description: '',
        startDate: '',
        endDate: '',
        type: '',
        status: KEY_PRIORITIES_STATUSES.ON_TRACK,
        progress: 0,
        deliverables: []
      },
      defaultRock: {
        email: this.loggedInUser.email,
        rock: '',
        description: '',
        startDate: '',
        endDate: '',
        type: '',
        status: KEY_PRIORITIES_STATUSES.ON_TRACK,
        progress: 0,
        deliverables: []
      },
      rockToDelete: null,
      groupedRocks: [],
      expandedItemIds: [],
      isFetchingLinkedItems: false,
      isUpdatingLinks: false,
      keyPriorityIdToLinkWithRocktool: null,
      keyPriorityIdToLinkWithVTO: null,
      linkedRocktoolsWithExistingKP: [],
      linkedVTOsWithExistingKP: [],
      selectedStatusFilters: []
    }
  },
  computed: {
    ...mapGetters(['loggedInUserData']),

    disableDrag () {
      return this.rocksData.sortOrder.length < 2 || !this.isEditable
    },
    hasRocks () {
      return this.rocksData.rocks.length > 0
    },

    showLinksDialog () {
      return !!(this.keyPriorityIdToLinkWithRocktool || this.keyPriorityIdToLinkWithVTO)
    },

    linkDialogTitle () {
      return this.keyPriorityIdToLinkWithRocktool ? 'Select Key priorities' : 'Select Vision & Plans'
    },

    userRocktools () {
      return this.loggedInUserData.linkedKeyPriorities
    },

    userVTOs () {
      return this.loggedInUserData.linkedVTOs
    },

    linkDialogData () {
      return this.keyPriorityIdToLinkWithRocktool ? this.userRocktools : this.userVTOs
    },

    selectedIds () {
      return this.keyPriorityIdToLinkWithRocktool ? this.linkedRocktoolsWithExistingKP : this.linkedVTOsWithExistingKP
    },

    rockStatuses () {
      const getCountByStatus = (status) => (this.initialRocksData.rocks || [])
        .reduce((prevVal, rock) => rock.status === status ? parseInt(prevVal) + 1 : prevVal, 0)

      return [{
        id: KEY_PRIORITIES_STATUSES.COMPLETED,
        text: 'Completed',
        count: getCountByStatus(KEY_PRIORITIES_STATUSES.COMPLETED)
      },
      {
        id: KEY_PRIORITIES_STATUSES.ON_TRACK,
        text: 'On Track',
        count: getCountByStatus(KEY_PRIORITIES_STATUSES.ON_TRACK)
      },
      {
        id: KEY_PRIORITIES_STATUSES.OFF_TRACK,
        text: 'Off Track',
        count: getCountByStatus(KEY_PRIORITIES_STATUSES.OFF_TRACK)
      },
      {
        id: KEY_PRIORITIES_STATUSES.INCOMPLETE,
        text: 'Incomplete',
        count: getCountByStatus(KEY_PRIORITIES_STATUSES.INCOMPLETE)
      }]
    }
  },
  watch: {
    initialRocksData: {
      immediate: true,
      handler (data) {
        this.rocksData = { ...this.rocksData, ...data }
        this.rocksData.rocks.forEach(rock => {
          rock.id = rock.id || this.$helpers.generateUniqueId()
          rock.deliverables = rock.deliverables || []
        })

        this.rocksData.rocks.sort((a, b) => {
          // compare type
          if (a.type !== b.type && a.type === '') return a.type < b.type
          // compare name
          if (a.email !== b.email) {
            const nameA = this.$options.filters.nameByEmail(a.email, this.attendees)
            const nameB = this.$options.filters.nameByEmail(b.email, this.attendees)
            return ('' + nameA).localeCompare(nameB)
          }
          // compare rock
          return ('' + a.rock).localeCompare(b.rock)
        })

        this.orderRocksByEmail()
      }
    }
  },
  methods: {
    ...mapActions([
      'toggleKeypriorityForItems',
      'syncKeypriority'
    ]),

    async loadLinkedRocktoolsAndVTOs (rockId, createIfNotExists = false) {
      try {
        this.isFetchingLinkedItems = true
        const object = await this.dataManager.loadObject('kpMapping', rockId)
        if (!object.exists) {
          // will help us to map keypriority to kps and vtos
          if (createIfNotExists) {
            await this.dataManager.createObject('kpMapping', {
              id: rockId,
              name: `Key priorities & VTO mappings for ${rockId}`,
              data: {
                groupId: this.level10Id,
                level10s: [this.level10Id],
                keypriorities: [],
                vtos: []
              }
            })
          }
          return []
        }

        const { data } = object.data()
        this.linkedRocktoolsWithExistingKP = data.keypriorities
        this.linkedVTOsWithExistingKP = data.vtos
        return data
      } catch (error) {
        this.$emit('set-message', { type: 'error', message: `Error loading linked items: ${error.message}` })
      } finally {
        this.isFetchingLinkedItems = false
      }
    },

    orderRocksByEmail () {
      // group rocks by email
      const groupedData = this.$options.filters.groupBy(this.rocksData.rocks, 'email')
      // add sort order attr if not present
      if (this.rocksData.sortOrder.length === 0) this.$set(this.rocksData, 'sortOrder', Object.keys(groupedData))
      // add email if not present
      // possibly user not part of a meeting or never visited but syncing his rock to L10 from KP
      //
      const missingUsers = Object.keys(groupedData).filter(email => !this.rocksData.sortOrder.includes(email))
      if (missingUsers.length > 0) {
        const sortOrder = [...this.rocksData.sortOrder, ...missingUsers]
        this.$set(this.rocksData, 'sortOrder', sortOrder)
      }

      // group notes by email
      this.groupedRocks = []
      this.rocksData.sortOrder.forEach(email => {
        const items = groupedData[email] || []
        if (items.length > 0) this.groupedRocks.push({ email, items })
      })
    },

    filteredRocks (rocks) {
      return rocks.filter(rock => this.selectedStatusFilters.length === 0 || this.selectedStatusFilters.includes(rock.status))
    },

    updateSortOrder () {
      const sortOrder = this.groupedRocks.map(g => g.email)
      this.$set(this.rocksData, 'sortOrder', sortOrder)
      this.emitUpdate()
    },

    toggleDetails (id) {
      const index = this.expandedItemIds.indexOf(id)
      index === -1 ? this.expandedItemIds.push(id) : this.expandedItemIds.splice(index, 1)
    },

    addRock (item = {}) {
      this.rockFields = {
        ...this.defaultRock,
        email: item.email || this.loggedInUser.email,
        type: item.type || 'Company',
        parentRockId: item.id || null,
        startDate: item.startDate || '',
        endDate: item.endDate || ''
      }
      this.showDialogRockForm = true
    },

    editRock ({ item, e }) {
      if (!this.isEditable || (e && e.target.nodeName === 'A')) return
      this.rockFields = { ...this.defaultRock, ...item }
      this.showDialogRockForm = true
    },

    updateRockFields (rockFields, keepForNext) {
      this.rockFields = Object.assign({}, rockFields)
      this.saveRock(keepForNext)
    },

    saveRock (keepForNext = false) {
      // validate
      if (!this.rockFields.email || !this.rockFields.rock) {
        return this.$emit('set-message', { type: 'error', message: 'Fill in the fields' })
      }

      let type = 'Key priority'
      let rocks = this.rocksData.rocks

      // if it's a deliverable
      if (this.rockFields.parentRockId) {
        type = 'Deliverable'
        const parentRockIndex = rocks.findIndex(rock => rock.id === this.rockFields.parentRockId)

        rocks = rocks[parentRockIndex].deliverables
        delete this.rockFields.deliverables
      }

      // update fields
      if (this.rockFields.id) {
        const index = rocks.findIndex(rock => rock.id === this.rockFields.id)
        if (index === -1) return this.$emit('set-message', { type: 'error', message: 'Key priority is deleted' })
        this.$set(rocks, index, this.rockFields)
        // sync
        this.syncKeypriority({
          keyPriority: this.rockFields,
          groupId: this.level10Id
        })
      } else {
        const newRock = Object.assign({
          id: this.$helpers.generateUniqueId()
        }, this.rockFields)
        rocks.push(newRock)
        // sync
        this.syncKeypriority({
          keyPriority: newRock,
          groupId: this.level10Id
        })
      }

      // add to the bottom of the list if it's a new user
      if (!this.rockFields.parentRockId && !this.rocksData.sortOrder.includes(this.rockFields.email)) {
        this.rocksData.sortOrder.push(this.rockFields.email)
      }

      this.emitUpdate()
      const msg = `${type} ${this.rockFields.id ? 'updated' : 'added'}`
      if (keepForNext) {
        this.$set(this.rockFields, 'rock', '')
        this.$set(this.rockFields, 'description', '')
      } else {
        this.closeDialogRockForm()
      }

      this.$emit('set-message', { type: 'success', message: msg })
    },

    closeDialogRockForm () {
      this.showDialogRockForm = false
    },

    openDialogRockConfirmDelete (item) {
      this.rockToDelete = item
      this.showDialogRockConfirmDelete = true
    },

    async deleteRock () {
      let type = 'Key priority'
      let rocks = this.rocksData.rocks
      let parentRock = null

      if (this.rockToDelete.parentRockId) {
        parentRock = rocks.find(rock => rock.id === this.rockToDelete.parentRockId)
        if (!parentRock) return this.$emit('set-message', { type: 'error', message: 'Key priority not found or is deleted' })

        type = 'Deliverable'
        rocks = parentRock.deliverables
      } else {
        await this.loadLinkedRocktoolsAndVTOs(this.rockToDelete.id)
        const linkedItems = [...this.linkedRocktoolsWithExistingKP, ...this.linkedVTOsWithExistingKP]
        if (linkedItems.length > 0) {
          this.updateKeyPriorityLinks(REMOVE_KP, this.rockToDelete.id, linkedItems)
          this.dataManager.hardDelete('kpMapping', this.rockToDelete.id)
        }
      }

      const index = rocks.findIndex(rock => rock.id === this.rockToDelete.id)
      if (index > -1) {
        rocks.splice(index, 1)
        this.emitUpdate()
        // sync
        if (parentRock) this.syncKeypriority({ ...parentRock, groupId: this.level10Id })
      }

      this.closeDialogRockConfirmDelete()
      this.closeDialogRockForm()
      this.$emit('set-message', {
        type: 'success',
        message: `${type} deleted`
      })
    },

    closeDialogRockConfirmDelete () {
      this.showDialogRockConfirmDelete = false
      this.rockToDelete = null
    },

    updateDate (item) {
      queue.push(() => this.syncKeypriority({
        keyPriority: item,
        groupId: this.level10Id
      }))
      this.emitUpdate()
    },

    emitUpdate () {
      this.$emit('data-updated', this.rocksData)
    },

    logIssue (rockObj) {
      const issue = `'${rockObj.type}' key priority '${rockObj.rock}' off track`
      this.$emit('add-ids-issue', issue)
    },

    linkWithRocktool (id) {
      this.keyPriorityIdToLinkWithRocktool = id
      this.loadLinkedRocktoolsAndVTOs(id, true)
    },

    linkWithVTO (id) {
      this.keyPriorityIdToLinkWithVTO = id
      this.loadLinkedRocktoolsAndVTOs(id, true)
    },

    closeLinksDialog () {
      this.keyPriorityIdToLinkWithRocktool = null
      this.keyPriorityIdToLinkWithVTO = null
      this.linkedRocktoolsWithExistingKP = []
      this.linkedVTOsWithExistingKP = []
    },

    async updateKeyPriorityRelations (ids) {
      try {
        this.isUpdatingLinks = true
        // find out newly selected or deleted links
        const checkAgainstList = this.keyPriorityIdToLinkWithRocktool ? this.linkedRocktoolsWithExistingKP : this.linkedVTOsWithExistingKP
        const newlySelected = ids.filter(id => !checkAgainstList.includes(id))
        const deletedFrom = checkAgainstList.filter(id => !ids.includes(id))
        // update mapping obj
        const kpId = this.keyPriorityIdToLinkWithRocktool || this.keyPriorityIdToLinkWithVTO
        this.keyPriorityIdToLinkWithRocktool ? await this.dataManager.updateObject('kpMapping', kpId, {
          'data.keypriorities': ids
        }) : await this.dataManager.updateObject('kpMapping', kpId, { 'data.vtos': ids })
        this.closeLinksDialog()
        this.$emit('set-message', { type: 'success', message: 'Data updated' })
        // insert or delete items to particular item
        if (newlySelected.length > 0) this.updateKeyPriorityLinks(ADD_KP, kpId, newlySelected)
        if (deletedFrom.length > 0) this.updateKeyPriorityLinks(REMOVE_KP, kpId, deletedFrom)
      } catch (error) {
        this.setMessage({ type: 'error', message: `Error updating relations: ${error.message}` })
      } finally {
        this.isUpdatingLinks = false
      }
    },

    updateKeyPriorityLinks (type, kpId, itemIds) {
      try {
        const keyPriority = this.rocksData.rocks.find(kp => kp.id === kpId)
        // trigger firebase function to add/remove from particular items
        queue.push(() => this.toggleKeypriorityForItems({
          type,
          keyPriority,
          linkedItems: itemIds.filter(id => id !== this.level10Id)
        }))
      } catch (error) {
        this.$emit('set-message', {
          type: 'error',
          message: `Error adding key priority to selected item(s): ${error.message}`
        })
      }
    }
  }
}
</script>
