<template>
  <v-menu
    v-model="dialogOpen"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
    offset-y
  >
    <template v-slot:activator="{ on: badge, attrs }">
      <v-badge
        v-show="hasComments"
        :content="totalComments"
        overlap
        class="mt-n5"
      />

      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon small class="ml-2" v-bind="attrs" v-on="{ ...tooltip, ...badge }">
            <v-icon size="20px">mdi-message-text-outline</v-icon>
          </v-btn>
        </template>
        <span>Add Note</span>
      </v-tooltip>
    </template>

    <v-card width="400px" min-height="300px">
      <v-card-title class="px-3 py-2">Notes</v-card-title>
      <v-divider></v-divider>

      <v-card-text class="pa-0 eos-issue__comment-wrapper" :class="{ 'eos-issue__comment-wrapper--full-height': !isEditable }">
        <template v-if="hasComments">
          <div
            v-for="(item, index) in commentsWithAuthor"
            :key="`issue-comment-${index}`"
            ref="issueComment"
            class="d-flex px-2 py-2 eos-issue__comment"
          >
            <AppAvatar
              :src="item.author.photoURL"
              :name="item.author.displayName"
              name-class="text-caption"
              class="mr-2"
            />
            <div class="d-flex flex-column flex-grow-1 eos-issue__comment__content">
              <div class="d-flex">
                <p class="text-body-2 black--text mb-1"><strong>{{ item.author.displayName }}</strong></p>
                <div class="ml-auto mr-1">
                  <v-icon
                    v-if="isEditable && item.author.email === loggedInUser.email"
                    small
                    class="mb-1 eos-issue__comment__btn-delete"
                    @click.stop="$emit('delete-comment', index)"
                  >mdi-delete</v-icon>
                  <span class="mx-1">{{ item.createdAt | humanize }}</span>
                </div>
              </div>
              <p class="text-nowrap black--text mb-0" v-html="$options.filters.nlToBr(item.comment)"></p>
            </div>
          </div>
        </template>
        <v-layout v-else column fill-height justify-center align-center>
          <p>No note found</p>
        </v-layout>
        <div id="dummy"></div>
      </v-card-text>

      <template v-if="isEditable">
        <v-divider />
        <v-card-actions class="d-flex flex-column px-4 py-3">
          <v-textarea
            v-model="comment"
            label="Note"
            rows="3"
            class="mt-3 eos-issue__comment__textarea"
            auto-grow
            outlined
            autofocus
            hide-details
          />
          <div class="d-flex mt-5 eos-issue__comment__btn">
            <v-spacer />
            <v-btn text @click="dialogOpen = false">Cancel</v-btn>
            <v-btn :disabled="comment.length === 0" color="success" class="ml-3" @click="addComment">Save</v-btn>
          </div>
        </v-card-actions>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'MeetingStepIDSIssueComments',
  props: {
    loggedInUser: {
      type: Object,
      default: () => ({})
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    comments: {
      type: Array,
      default: () => ([])
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dialogOpen: false,
      comment: ''
    }
  },
  computed: {
    commentsWithAuthor () {
      const comments = this.comments.slice()
      return comments.map(comment => ({
        ...comment,
        author: this.attendees.find(u => u.email === comment.email)
      }))
    },
    totalComments () {
      return this.commentsWithAuthor.length
    },
    hasComments () {
      return this.totalComments > 0
    }
  },
  watch: {
    async dialogOpen (isOpen) {
      if (!isOpen || !this.hasComments) return
      this.scrollToEnd()
    }
  },
  methods: {
    scrollToEnd () {
      setTimeout(() => {
        const div = this.$refs.issueComment[this.totalComments - 1]
        div.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }, 500)
    },
    addComment () {
      if (this.loggedInUser.isAnonymous) {
        return this.$emit('set-message', {
          type: 'error',
          message: 'Please sign in to add comment'
        })
      }

      this.$emit('add-comment', {
        email: this.loggedInUser.email,
        comment: this.comment,
        createdAt: this.$helpers.getUnixTimestamp()
      })

      this.comment = ''
      this.scrollToEnd()
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-issue__comment-wrapper {
  height: 195px;
  overflow-y: auto;
}

.eos-issue__comment-wrapper--full-height {
  height: 250px;
}

.eos-issue__comment {
  position: relative;

  .eos-issue__comment__btn-delete {
    cursor: pointer;
    visibility: hidden;
  }

  &:hover {
    background-color: #eeeeee;

    .eos-issue__comment__btn-delete {
      visibility: visible;
    }
  }
}

.eos-issue__comment__content {
  overflow: hidden;
}

.eos-issue__comment__textarea,
.eos-issue__comment__btn {
  width: 100%;
}
</style>
