<template>
  <v-dialog :value="true" scrollable persistent max-width="400px">
    <form @submit.prevent="save">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <template v-if="!fetchingItems">
            <v-list-item-group
              v-if="data.length > 0"
              v-model="selectedItemIndexes"
              color="primary"
              multiple
            >
              <v-list-item v-for="(item, index) in data" :key="`kp-link-${item.id}`" dense>
                <v-list-item-icon class="mr-2">
                  <v-icon :color="selectedItemIndexes.includes(index) ? 'primary' : ''">
                    {{ `mdi-checkbox-${selectedItemIndexes.includes(index) ? 'marked' : 'blank-outline' }`}}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ item.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <div v-else class="d-flex px-5 py-3">
              <span>No data found</span>
            </div>
          </template>
          <div v-else class="d-flex justify-center py-5">
            <v-progress-circular indeterminate color="primary"/>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="mt-2 py-1">
          <v-spacer />
          <v-btn text class="mb-2" @click="$emit('close')">Cancel</v-btn>
          <v-btn color="success" class="mr-2 mb-2" :loading="showLoader" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
export default {
  name: 'KeyPriorityDialogLinks',
  props: {
    title: {
      type: String,
      default: ''
    },
    selectedIds: {
      type: Array,
      default: () => ([])
    },
    data: {
      type: Array,
      default: () => ([])
    },
    fetchingItems: {
      type: Boolean,
      default: false
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedItemIndexes: []
    }
  },
  watch: {
    selectedIds: {
      immediate: true,
      handler (ids) {
        this.selectedItemIndexes = ids.map(id => this.data.findIndex(item => item.id === id))
      }
    }
  },
  methods: {
    save () {
      const ids = this.selectedItemIndexes.map(index => this.data[index].id)
      this.$emit('update-relation', ids)
    }
  }
}
</script>
