<template>
  <v-dialog v-model="value" persistent scrollable max-width="400px">
    <form @submit.prevent="validateAndSave(false)">
      <v-card>
        <v-card-title>
          <span class="headline">{{ todoFormTitle }}</span>
          <v-spacer />
          <v-btn
            class="ma-0 pa-0"
            color="primary"
            text
            small
            @click="$emit('manage-attendees')"
          >Manage attendees</v-btn>
        </v-card-title>
        <v-divider />

        <v-card-text class="pt-0">
          <v-alert
            v-if="twSyncOptions.accessToken && todoFields.projectId && missingHandles.length > 0"
            outlined
            color="error"
            class="text-body-2 mt-3 px-2 pt-1 pb-2"
          >
            <small>
              Please make sure the below email
              <template v-if="missingHandles.length > 1">addresses are</template>
              <template v-else>address is</template>
              registered with teamwork. Otherwise, the to-do will get assgined to <strong>Guest User</strong> in the task list.
              <ul>
                <li
                  v-for="email in missingHandles"
                  :key="`missinghandles-${email}`"
                >
                  <strong>{{ email }}</strong>
                </li>
              </ul>
            </small>
          </v-alert>
          <v-select
            v-model="allAssignees"
            :menu-props="{ offsetY: true }"
            :items="attendees"
            item-value="email"
            item-text="displayName"
            label="Who"
            class="pr-1 mt-5"
            outlined
            multiple
            hide-details
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item.displayName }}</span>
              <span v-if="index === 1" class="mx-1">
                + {{ allAssignees.length - 1 }}
                <template v-if="allAssignees.length - 1 > 1">others</template>
                <template v-else>other</template>
              </span>
            </template>
          </v-select>
          <v-checkbox
            v-if="!todoFields.id"
            v-show="allAssignees.length > 1"
            v-model="createTasksForEachIndividual"
            label="Create a task for each assignee"
            class="mt-1 mb-6"
            :ripple="false"
            hide-details
          />

          <v-textarea
            v-model="todoFields.todo"
            label="To-do"
            rows="4"
            class="pb-0 mt-5 mb-3"
            outlined
            autofocus
            hide-details
          />

          <v-container
            pa-0
            grid-list-md
            fluid
          >
            <v-layout pa-0 ma-0 row justify-space-between class="eos-todo__datepicker">
              <v-flex pl-0 sm6>
                <p class="mb-1 text-caption">Start Date</p>
                <AppDatePicker
                  default-text="Select Date"
                  square
                  :date="todoFields.startDate"
                  @change="todoFields.startDate = $event"
                />
              </v-flex>
              <v-flex pr-0 sm6>
                <p class="mb-1 text-caption">End Date</p>
                <AppDatePicker
                  default-text="Select Date"
                  square
                  :date="todoFields.endDate"
                  @change="todoFields.endDate = $event"
                />
              </v-flex>
            </v-layout>
          </v-container>

          <v-container v-if="withStatusAndProgress" pa-0 mx-0 mb-0 mt-6 grid-list-md fluid>
            <v-layout pa-0 ma-0 row>
              <v-flex pa-0 ma-0 sm7>
                <v-select
                  v-model="todoFields.status"
                  :menu-props="{ offsetY: true }"
                  :items="['Not done','Done']"
                  label="Status"
                  class="pr-1"
                  outlined
                  hide-details
                />
              </v-flex>
              <v-flex pa-0 ma-0 sm5>
                <v-select
                  v-model="todoFields.progress"
                  :menu-props="{ offsetY: true }"
                  :items="[
                    { value: 0, text: '0%'},
                    { value: 10, text: '10%' },
                    { value: 20, text: '20%' },
                    { value: 30, text: '30%' },
                    { value: 40, text: '40%' },
                    { value: 50, text: '50%' },
                    { value: 60, text: '60%' },
                    { value: 70, text: '70%' },
                    { value: 80, text: '80%' },
                    { value: 90, text: '90%' },
                    { value: 99.99, text: '99.99%' }
                  ]"
                  label="Progress"
                  class="pl-1"
                  outlined
                  hide-details
                  @change="$event === 99.99 ? $emit('set-message', { type: 'success', message: 'Funny' }) : null"
                />
              </v-flex>
            </v-layout>
          </v-container>

          <template v-if="!!twSyncOptions.accessToken">
            <p class="black--text mt-8">Sync with Teamwork Todo</p>
            <v-select
              v-model="todoFields.projectId"
              :menu-props="{ offsetY: true, maxWidth: 363 }"
              :items="projects"
              item-value="id"
              item-text="name"
              label="Project"
              class="pr-1"
              outlined
              hide-details
              @change="fetchTasklists"
            />

            <v-select
              v-model="todoFields.tasklistId"
              :menu-props="{ offsetY: true, maxWidth: 363 }"
              :loading="fetchingTasklists"
              :disabled="projects.length === 0"
              :items="tasklists"
              item-value="id"
              item-text="name"
              label="Tasklist"
              class="pr-1 mt-5"
              outlined
              hide-details
            />
          </template>
        </v-card-text>

        <v-divider />
        <v-card-actions class="mt-1">
          <v-btn
            v-if="todoFields.id"
            color="error"
            class="ml-2 mb-2"
            text
            @click="$emit('delete', todoFields)"
          >Delete</v-btn>
          <v-spacer />
          <v-btn text class="mb-2" @click="close">Cancel</v-btn>
          <v-btn
            v-if="!todoFields.id"
            depressed
            class="mr-2 mb-2"
            @click="validateAndSave(true)"
          >Save + Next</v-btn>
          <v-btn
            color="success"
            class="mr-2 mb-2"
            @click="validateAndSave(false)"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>

<script>
import AppDatePicker from '@/components/shared/datepickers/AppDatePicker'
import { dateToYYYYMMDD } from '@/filters/dateToYYYYMMDD'

export default {
  name: 'MeetingDialogTodoForm',
  components: { AppDatePicker },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    attendees: {
      type: Array,
      default: () => ([])
    },
    twProjects: {
      type: Array,
      default: () => ([])
    },
    twTasklists: {
      type: Array,
      default: () => ([])
    },
    twSyncOptions: {
      type: Object,
      default: () => ({})
    },
    fields: {
      type: Object,
      default: () => ({})
    },
    withStatusAndProgress: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      allAssignees: [],
      createTasksForEachIndividual: false,
      todoFields: {
        email: '',
        projectId: null,
        tasklistId: null,
        taskId: null,
        todo: '',
        status: 'Not done',
        progress: 0,
        startDate: '',
        endDate: ''
      },
      projects: [],
      tasklists: [],
      fetchingTasklists: false
    }
  },
  computed: {
    todoFormTitle () {
      return `${(this.todoFields.id ? 'Edit' : 'New')} Todo`
    },
    missingHandles () {
      return this.allAssignees.filter(email => this.attendees.some(attendee => attendee.email === email && !attendee.twId))
    }
  },
  watch: {
    fields: {
      immediate: true,
      handler (fields) {
        this.createTasksForEachIndividual = false
        // set to defaults
        this.projects = this.twProjects.slice()
        this.tasklists = this.twTasklists.slice()

        // delete extra attributes
        if (!this.withStatusAndProgress) {
          delete this.todoFields.status
          delete this.todoFields.progress
        }
        delete this.todoFields.id
        delete this.todoFields.isNew

        this.allAssignees = [...fields.email.split(','), ...fields.otherAssignees || []]

        // update fields
        this.todoFields = { ...this.todoFields, ...fields }
        // fix for a typo
        this.todoFields.status = (!this.todoFields.status || this.todoFields.status === 'Note Done') ? 'Not done' : this.todoFields.status
        // type casting to avoid comparision issues
        this.todoFields.projectId = this.todoFields.projectId ? this.todoFields.projectId.toString() : null
        this.todoFields.tasklistId = this.todoFields.tasklistId ? this.todoFields.tasklistId.toString() : null
        // fetch tasklist if selected diff tasklists
        if (this.todoFields.projectId && this.todoFields.projectId !== this.twSyncOptions.projectId) this.fetchTasklists(fields.projectId)
      }
    },
    twTasklists (tasklists) {
      this.fetchingTasklists = false
      this.tasklists = tasklists.slice()
    },
    'todoFields.startDate': {
      handler (date) {
        if (!date) return // no start date
        const endDate = dateToYYYYMMDD(new Date(date).setDate(new Date(date).getDate() + 7))

        // end date should be same if no end date selected
        if (!this.todoFields.endDate) {
          this.todoFields.endDate = endDate
          return
        }

        // end date should be >= start date
        const diffTime = new Date(this.todoFields.endDate) - new Date(date)
        if (diffTime < 0) this.todoFields.endDate = endDate
      }
    },
    'todoFields.endDate': {
      handler (date) {
        if (!date) return // no end date

        // start date should be same if no start date selected
        if (!this.todoFields.startDate) {
          this.todoFields.startDate = date
          return
        }

        // end date should be >= start date
        const diffTime = new Date(date) - new Date(this.todoFields.startDate)
        if (diffTime < 0) this.todoFields.startDate = date
      }
    }
  },
  methods: {
    fetchTasklists (projectId) {
      this.fetchingTasklists = true
      this.$emit('refresh-tasklist', projectId)
    },
    validateAndSave (keepOpenForNext) {
      const assignees = this.allAssignees.slice()
      this.todoFields.email = assignees[0]

      assignees.shift()
      const otherAssignees = assignees

      // check for other assignees, remove first one selected
      if (otherAssignees.length > 0 && !this.createTasksForEachIndividual) {
        this.todoFields.otherAssignees = otherAssignees
      } else {
        this.todoFields.otherAssignees = []
      }

      // validate
      if (!this.todoFields.email || !this.todoFields.todo) {
        return this.$emit('set-message', { type: 'error', message: 'Fill in the fields' })
      }
      // selected project but not tasklist
      if (this.todoFields.projectId && !this.todoFields.tasklistId) {
        return this.$emit('set-message', { type: 'error', message: 'Please select the tasklist' })
      }

      const evtName = keepOpenForNext ? 'save-next' : 'save'
      this.$emit(evtName, this.todoFields)

      // create tasks for each assignee
      if (this.createTasksForEachIndividual && otherAssignees.length > 0) {
        otherAssignees.forEach(assignee => this.$emit(evtName, { ...this.todoFields, email: assignee }))
      }
    },
    close () {
      if (this.todoFields.projectId !== this.twSyncOptions.projectId) this.fetchTasklists()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.eos-todo__datepicker {
  ::v-deep .v-chip {
    width: 100%;
    margin: 0px !important;

    &:not(:hover) {
      border-color: #a1a1a1 !important;
    }
  }
}
</style>
